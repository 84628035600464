<template>
    <div class="active-chart-table-container" style="display: flex; flex-wrap: wrap; margin-left: 0; background-color: white; margin-left: 1em; border-radius: 8px; overflow-y: auto">
        <div class="chart-approval-only" style="height: 70vh;display: flex; flex-wrap: wrap; margin-left: 0; width: 100%; border-radius: 8px;" :style="loadingPatient || !patient || loadingResidentDrugs ? {backgroundColor: '#f3f4f4'}:{}">
            <div v-if="!loadingPatient && !loadingResidentDrugs && patient && patient.personal_information && patient.regulatory_information" style="width: 100%">
                <div class="admin-chart-form-container">
                    <div class="admin-chart-form" >
                        <!-- PATIENT SUMMARY HEADER -->
                        <!-- GENERAL INFO -->
                        <div class="general-patient"
                            style="grid-area: initial; border-top-left-radius: 8px; border-bottom-left-radius: 8px; display: grid; grid-template-columns: 1fr 4fr; grid-gap: 10px;">
                            <div>
                                <router-link :to="'/patient/' + patient.uuid" style="text-decoration: none; color: #0870DC">
                                    <h2 class="selected-resident-name" style="padding-top: 0px; margin: 0; color: #0870DC; font-size: 1.2em;">
                                        {{ patient.personal_information.first_name }} {{ patient.personal_information.last_name }}
                                    </h2>
                                </router-link>
                               
                                <img class="resident-image large admin" onerror="this.onerror=null; this.className='resident-image-default resident-image large admin'" v-if="patient.image_information && patient.image_information.item" :src="patient.image_information.url">
                                <img v-else class="resident-image-default resident-image large admin">
                                <button class="prn-button" @click="showPRN()" :style="patient.displayPRN ? {color: 'white', backgroundColor: '#00B2A2'}:{color: '#00B2A2', backgroundColor: 'white'}"
                                style="width: 100%; border: 2px solid #00B2A2;  font-weight: bold;">PRN</button>
                                <button class="nim-button" @click="showNIM()" 
                                :style="patient.displayNIM ? {color: 'white', backgroundColor: '#274188'}:{color: '#274188', backgroundColor: 'white'}"
                                style="width: 100%; border: 2px solid #274188;  font-weight: bold;">NIM</button>
                                <!-- <button class="outline-button" @click="showPatch()" :style="patient.displayPatch ? {color: 'white', backgroundColor: '#EF9600'}:{color: '#EF9600', backgroundColor: 'white'}"
                                style="width: 100%; border: 2px solid #EF9600;  font-weight: bold;">PATCH</button> -->
                                <div>
                                    <div :class="infoADRTab == 'INFO' ? 'info-tab-border': 'adr-tab-border'">
                                        <!-- PATIENT INFO/ADR TAB SELECT -->
                                        <div style="display: flex; justify-content: space-around; align-items: center;" :class="infoADRTab == 'INFO' ? 'info-tab-bottom': 'adr-tab-bottom'">
                                            <h4 style="cursor: pointer; width: 100%; margin: 0; padding: 5px 0; width: 50%;"
                                                @click="infoADRTab = 'INFO'"
                                                :class="infoADRTab == 'INFO' ? 'info-tab' : {}">
                                                INFO</h4>
                                            <h4 style="cursor: pointer; width: 50%; margin: 0; padding: 5px 0;"
                                                @click="infoADRTab = 'ADR'"
                                                :class="infoADRTab == 'ADR' ? 'adr-tab' : {}">
                                                ADR</h4>
                                        </div>
                                        <!-- PATIENT STICKERS -->
                                        <div style="height: 130px; overflow: auto;" v-if="infoADRTab == 'ADR'">
                                            <table style="padding: 0 10px; border-collapse: collapse;" v-if="patient.adr && patient.adr.length">
                                                <tr>
                                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                                        Drug
                                                    </th>
                                                    <th style="border-bottom: 2px solid #b6b6b6; padding: 0 10px;">
                                                        Year
                                                    </th>
                                                </tr>
                                                <tr v-for="(item, index) in patient.adr" :key="index">
                                                    <td style="padding: 0 10px;">
                                                        {{ item.drug ? item.drug.toUpperCase() : item.custom ? item.custom.toUpperCase() : 'Non Spec.'}}
                                                    </td>
                                                    <td style="padding: 0 10px;">
                                                        {{ item.date ? item.date.split(',')[1] : '----' }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!-- PATIENT INFO -->
                                        <div style="height: 130px; text-align: left; overflow-x: auto; background-color: white !important;border-radius: 8px"
                                            v-if="infoADRTab == 'INFO'">
                                            <div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">DOB</p>
                                                    <p class="general-patient-info-value">{{ patient.personal_information.dob }} ({{ patient.personal_information.age }} yo)</p>
                                                </div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">Sex</p>
                                                    <p class="general-patient-info-value">{{ patient.personal_information.sex }}</p>
                                                </div>
                                                <div class="general-patient-info">
                                                    <p class="general-patient-info-key">Preferred Name</p>
                                                    <p class="general-patient-info-value">
                                                        {{ patient.personal_information.preferred_name }}</p>
                                                </div>
                                                <div class="general-patient-info" v-for="(item, index) in patient.contact_methods"
                                                    :key="index">
                                                    <p class="general-patient-info-key">{{ item.type }}</p>
                                                    <p class="general-patient-info-value">{{ item.data }}</p>
                                                </div>
                                                <div class="general-patient-info"
                                                    v-for="(item, index) in patient.regulatory_information" :key="index">
                                                    <p class="general-patient-info-key">{{ item.regulatory_type }}</p>
                                                    <p class="general-patient-info-value">{{ item.data }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SPECIAL CONSID.-->
                                    <div>
                                        <div v-if="spec_cons && $store.state.user.patient_admin_active">
                                            <div
                                                v-if="spec_cons.special_considerations && spec_cons.special_considerations.length"
                                                style="display: flex; flex-wrap: wrap; padding: 5px; background-color: white; border-radius: 8px; margin-top: 2px; overflow: auto; max-height: 200px;"
                                            >
                                                <p
                                                    class="field-value"
                                                    v-for="(item, index) in uniqueSpecCons" :key="index"
                                                    style="color: white; font-weight: bold; background-color: #E04F39; border-radius: 30px; height: fit-content; padding: 3px 10px; margin-top: 0px; margin-right: 5px;"
                                                >
                                                    {{item.name.toUpperCase() }}
                                                </p>
                                            </div>
                                            <div style="padding: 10px; border-radius: 4px; border: 2px solid red" v-if="spec_cons.comments">
                                                <span style="font-weight: bold; text-align: left;">Notes: </span>{{ spec_cons.comments }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- END SPECIAL CONSID. -->
                                </div>
                            </div>
                            <!-- PATIENT ADMINISTRATION COMPONENTS -->
                            <!-- <div style="max-height: 75vh; overflow-y: auto"> -->
                            <div style="max-height: 70vh; overflow-y: auto">

                                <!-- EXPIRED MEDICATIONS -->
                                <div class="medication-group" v-if="displayExpiredCharts && (expiredCharts && expiredCharts.length && $store.state.user?.role?.permissions?.includes('can_view_expired_charts'))">
                                    <div class="red-background medication-group-header" 
                                    style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px; background-color: #7d2b1f">
                                        <h2 style="margin: 0;">Expired Medications</h2>
                                    </div>
                                <div class="medication-group" >
                                    <div>
                                    <table style="border-collapse: collapse;" >
                                        <tr class="non-packed-medication-row" style="background-color: rgba(171, 58, 41, 0.3) !important" v-for="(drug, i) in expiredCharts" :key="i">
                                        <td v-if="mimsApiKey" style="width: 10em">
                                            <MimsImage
                                                :alias_id="drug.alias ? drug.alias.alias_id : ''"
                                                off_market="true"
                                                width="90px"
                                                height="60px">
                                            </MimsImage>
                                        </td>
                                        <td style="padding: 10px"><p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}} <span class="green-button" style="margin: 3px; background-color: rgb(0, 178, 162) !important; padding: 2px 10px;" v-if="drug.type == 'prn'">PRN</span></p>
                                        <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                            <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                            <p style="font-size: 1em; color: #E04F39; font-weight: bold;">{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                            <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[0].administered_at  : 'N/A'}}</b></p>
                                            <div style="display: flex; flex-wrap: wrap">
                                                <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                    <p class="consideration-tag" style="width: fit-content" v-if="tag.toUpperCase() != 'S4' && tag.toUpperCase() != 'S8' && tag.toUpperCase() != 'S4VIC'" >{{tag.toUpperCase()}}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td style="width: 5em">x {{drug.dose}}</td>
                                        <td style="width: 7em;">{{drug.route}}</td>
                                       <td style="width: 1em">
                                            <div>
                                                <p v-if="$store.state.user?.role?.permissions?.includes('can_administer_expired_charts')" class="blue-button" style="background-color: rgb(171 58 41) !important" @click="createExpiredNote(drug), displayExpiredNoteModal=true">ACTION</p>
                                                <!-- <Dropdown placeholder="ACTION" :options="expiredOptions" optionLabel="name" optionValue="value" v-model="drug.selectedExpiredOption" /> -->
                                            </div>
                                        </td>
                                        <td style="width: 1em">
                                        </td>
                                        <!-- <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" colspan="2" style="display: flex; flex-direction: column;">
                                            <div
                                                v-if="!drug.doc"
                                                class="administered"
                                                style="display: flex; align-items: center; width: 6em; text-align: center; padding-right: 10px; justify-content: center; border-radius: 6px;">
                                                <p style="padding: 5px 10px; text-align: center;">DONE</p>
                                            </div>
                                            <div
                                                v-if="drug.doc"
                                                class="not-administered" 
                                                style="display: flex; align-items: center; width: 6em; text-align: center; padding-right: 10px; justify-content: center; border-radius: 6px; padding: 5px 10px;">
                                                <p style=" text-align: center;">{{drug.doc.toUpperCase().slice(0,8)}}</p>
                                            </div>
                                            <div v-if="redoingAdministration[drug.chart_id]?.error" style="color: red; font-size: 15px;">Error: Redo Failed.</div>
                                        </td> -->
                                        <td v-if="redoingAdministration[drug.chart_id]?.loading" style="width: 1em">
                                            <div ><i class="pi pi-spin pi-spinner" :id="drug.chart_id+ '-redoing'"></i></div>
                                        </td>
                                        <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" @click="redoAdminChart(drug)" style="width: 1em">
                                            <div ><i class="pi pi-replay" :id="drug.chart_id+ '-redo'"></i></div>
                                        </td>
                                        <td style="width: 0em"></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                                </div>

                                <!-- PACKED MEDICATIONS -->
                                <div class="medication-group" v-if="getPacked.length && !patient.displayPRN && !patient.displayNIM && !patient.displayPatch">
                                    <div class="strong-blue-background medication-group-header" 
                                    style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                        <h2 style="margin: 0;">Packed Medications <span style="margin-left: 30px">({{getIncompletePacked.length}} Medication{{getPacked.length > 1 ? 's':''}} Requiring Action)</span></h2>
                                        <div style="display: flex; align-items: center" v-if="getIncompletePacked.length">
                                            <h4 style="margin: 0;margin-left: 3.3em; color: white;">All</h4>
                                            <!-- <i class="pi pi-check" style="margin-left: 2.3em" @click="packedSubmit()"></i> -->
                                            <SingleVerify style="margin-left: 2.3em" @submitIsCompleted="submitIsCompleted" :payload="submitPayload" @click="mapPackedPayload(true)"
                                                endpoint="/administer-bulk" :checkIcon="true" :loop="true" :bulkAdminister="true"  />
                                            <i class="pi pi-times" style="margin-left: 1.4em; margin-right: 1.4em;" @click="displayDOCModal = true, mapPackedPayload(false)"></i>
                                        </div>
                                        <!-- DISABLED BUTTON DISPLAY -->
                                        <div v-else style="display: flex; align-items: center" >
                                            <h4 style="margin: 0;margin-left: 3.3em; margin-right: 15px;  color: white;">All</h4>
                                            <i class="pi pi-check" style="color:lightgrey; cursor: inherit"></i>
                                            <i class="pi pi-times" style="margin-left: 1.4em; margin-right: 1.4em; color:lightgrey; cursor: inherit"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <!--  -->
                                        <table style="border-collapse: collapse;" >
                                            <tr class="packed-medication-row" v-for="(drug, i) in getIncompletePacked" :key="i">
                                                <td v-if="mimsApiKey && loadMims" style="width: 10em">
                                                    <MimsImage
                                                        :alias_id="drug.dispensed_items?.length > 0 ? drug.dispensed_items[0].alias_id : drug.alias ? drug.alias.alias_id : ''"
                                                        off_market="true"
                                                        width="90px"
                                                        height="60px">
                                                    </MimsImage>
                                                </td>
                                                <td style="padding:10px; ">
                                                    <p style="font-weight: bold; font-size: 1em;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>
                                                    <p v-if="expiryBool(drug.stop_date)" style="font-size: 1em;color: #E04F39;font-weight: bold"> Expiring in {{ formatExpiry(drug.stop_date) }} </p>
                                                    <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                                    <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                                    <p>{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                                    <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[drug.last_chart_administrations.length - 1].administered_at  : 'N/A'}}</b></p>
                                                    <div style="display: flex; flex-wrap: wrap">
                                                        <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                            <p class="consideration-tag" style="width: fit-content; font-weight: bold;" v-if="!hiddenTags.includes(tag.toUpperCase())" >{{tag.toUpperCase()}}</p>
                                                        </div>
                                                    </div>
                                                    <p class="orange-button" style="width: fit-content" v-if="!drug.requestedSupply" @click="resupplyDrug(drug)">{{ !drug.loadingSupply ? 'REQ. RESUPPLY' : 'REQUESTING...'}}</p>
                                                    <p class="green-outline-button" style="color: #00823c; border: 2px solid #00823c" v-if="drug.requestedSupply">RESUPPLY REQUESTED</p>
                                                </td>
                                                <td style="font-weight: bold; width: 5em">x {{drug.dose}}</td>
                                                <td style="font-weight: bold; width: 7em;">{{drug.route}}</td>
                                                <td v-if="!drug.completed"  style="width: 1em">
                                                    <SingleVerify @submitIsCompleted="submitIsCompleted" :indices="i" :payload="submitPayload" @click="selectMedLoad(drug, true)"
                                                    endpoint="/administer" :status="(drug.status == 'N' || drug.status == 'R' || drug.status == 'W') ? 'danger': drug.status == 'approve'? 'good' : 'warning'" 
                                                    :warning="drug.status == 'N' ? 'Medication not available - Please notify prescriber': drug.status == 'R' ? 
                                                    'Resident Refused - Please notify prescriber': drug.status == 'W' ?
                                                    'Medication withheld for clinical reasons - Please notify prescriber':''" 
                                                    :dual="(drug.chart_type == 'Insulin Order' ||  drug.chart_type == 'Variable Dose') ? true: false" 
                                                    :checkIcon="true" :elemId="drug.chart_id+ '-approve'" />
                                                </td>
                                                <td v-if="!drug.completed" @click="displayDOCModal = true, selectMedLoad(drug, false)" style="width: 1em">
                                                    <div>
                                                        <i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i>
                                                    </div>
                                                </td>
                                                <td v-if="drug.completed" colspan="2" style="display: table-cell; justify-content: end; align-items: center; ">
                                                    <div v-if="!drug.doc" class="administered" 
                                                        style="display: flex; justify-content: center; align-items: center; width: 6em; text-align: center; padding-right: 10px; border-radius: 6px; padding: 5px 10px;">
                                                        <!-- <i class="pi pi-check administered"></i>  -->
                                                        <p style=" text-align: center;">DONE</p>
                                                    </div>
                                                    <div v-if="drug.doc" class="not-administered" 
                                                        style=" display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 6em;
                                                        text-align: center;
                                                        padding-right: 10px;
                                                        border-radius: 6px;
                                                        padding: 5px 10px;">
                                                        <!-- <i class="pi pi-times not-administered"></i>  -->
                                                        <p style=" text-align: center;">{{drug.doc.toUpperCase().slice(0,8)}}</p>
                                                    </div>
                                                </td>
                                                
                                                <td style="width: 0em"></td>
                                            </tr>
                                            <!-- DISPLAY INDIVIDUALLY COMPLETED PACKED MEDS BELOW THE INCOMPLETED PACKED MEDS -->
                                            <tr class="packed-medication-row" v-for="(drug, i) in getCompletePacked" :key="i">
                                                <td v-if="mimsApiKey && loadMims" style="width: 10em">
                                                    <MimsImage
                                                        :alias_id="drug.dispensed_items?.length > 0 ? drug.dispensed_items[0].alias_id : drug.alias ? drug.alias.alias_id : ''"
                                                        off_market="true"
                                                        width="90px"
                                                        height="60px">
                                                    </MimsImage>
                                                </td>
                                                <td style="padding:10px; ">
                                                    <p style="font-weight: bold; font-size: 1em;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>
                                                    <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                                    <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                                    <p style="font-size: 1em;">{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                                    <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[drug.last_chart_administrations.length - 1].administered_at  : 'N/A'}}</b></p>
                                                    <div style="display: flex; flex-wrap: wrap">
                                                        <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                            <p class="consideration-tag" style="width: fit-content; font-weight: bold;" v-if="!hiddenTags.includes(tag.toUpperCase())" >{{tag.toUpperCase()}}</p>
                                                        </div>
                                                    </div>
                                                    <p class="orange-button" style="width: fit-content" v-if="!drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')" @click="resupplyDrug(drug)">{{ !drug.loadingSupply ? 'REQ. RESUPPLY' : 'REQUESTING...'}}</p>
                                            <p class="green-outline-button" style="color: #00823c; border: 2px solid #00823c" v-if="drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')">RESUPPLY REQUESTED</p>
                                                </td>
                                                <td style="font-weight: bold; width: 5em">x {{drug.dose}}</td>
                                                <td style="font-weight: bold; width: 7em;">{{drug.route}}</td>
                                                <td v-if="!drug.completed"  style="width: 1em">
                                                    <SingleVerify @submitIsCompleted="submitIsCompleted" :indices="i" :payload="submitPayload" @click="selectMedLoad(drug, true)"
                                                    endpoint="/administer" :status="(drug.status == 'N' || drug.status == 'R' || drug.status == 'W') ? 'danger': drug.status == 'approve'? 'good' : 'warning'" 
                                                    :warning="drug.status == 'N' ? 'Medication not available - Please notify prescriber': drug.status == 'R' ? 
                                                    'Resident Refused - Please notify prescriber': drug.status == 'W' ?
                                                    'Medication withheld for clinical reasons - Please notify prescriber':''" 
                                                    :dual="(drug.chart_type == 'Insulin Order' ||  drug.chart_type == 'Variable Dose') ? true: false" 
                                                    :checkIcon="true" :elemId="drug.chart_id+ '-approve'" />
                                                </td>
                                                <td v-if="!drug.completed" @click="displayDOCModal = true, selectMedLoad(drug, false)" style="width: 1em">
                                                    <div ><i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i></div>
                                                </td>
                                                <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" colspan="2" style="justify-content: end; align-items: center; margin-top: 33px; display: flex; flex-direction: column;">
                                                    <div v-if="!drug.doc" class="administered" 
                                                        style="display: flex; justify-content: center; align-items: center; width: 6em; text-align: center; padding-right: 10px; border-radius: 6px; padding: 5px 10px;">
                                                        <!-- <i class="pi pi-check administered"></i>  -->
                                                        <p style=" text-align: center;">DONE</p>
                                                    </div>
                                                    <div v-if="drug.doc" class="not-administered" 
                                                        style="display: flex; align-items: center; justify-content: center; width: 6em; text-align: center; padding-right: 10px; border-radius: 6px; padding: 5px 10px;">
                                                        <!-- <i class="pi pi-times not-administered"></i>  -->
                                                        <p style=" text-align: center;">{{drug.doc.toUpperCase().slice(0,8)}}</p>
                                                    </div>
                                                    <!-- Error message if a redoingAdminChart action goes wrong. Check console for error details -->
                                                    <div v-if="redoingAdministration[drug.chart_id]?.error" style="color: red; font-size: 15px;">Error: Redo Failed.</div>
                                                </td>
                                                <td v-if="redoingAdministration[drug.chart_id]?.loading" style="width: 1em">
                                                    <div ><i class="pi pi-spin pi-spinner" :id="drug.chart_id+ '-redoing'"></i></div>
                                                </td>
                                                <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" @click="redoAdminChart(drug)" style="width: 1em">
                                                    <div ><i class="pi pi-replay" :id="drug.chart_id+ '-redo'"></i></div>
                                                </td>
                                                <td style="width: 0em"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <!-- S8/S4D MEDICATIONS -->
                                <div class="medication-group" v-if="getS8.length && !patient.displayPRN && !patient.displayNIM && !patient.displayPatch">
                                    <div class="red-background medication-group-header" 
                                    style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                        <h2 style="margin: 0;">Drug Register Medications <span style="margin-left: 30px">({{getIncompleteS8.length}} Medication{{getIncompleteS8.length > 1 ? 's':''}} Requiring Action)</span></h2>
                                    </div>
                                    <div>
                                        <table style="border-collapse: collapse;"  >
                                            <tr class="scheduled-medication-row" v-for="(drug, i) in getS8" :key="i">
                                                <td v-if="mimsApiKey && loadMims" style="width: 10em">
                                                    <MimsImage
                                                       :alias_id="drug.dispensed_items?.length > 0 ? drug.dispensed_items[0].alias_id : drug.alias ? drug.alias.alias_id : ''"
                                                        off_market="true"
                                                        width="90px"
                                                        height="60px">
                                                    </MimsImage>
                                                </td>
                                                <td style="padding:10px;">
                                                    <p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}} <i>({{drug.stock_items && drug.stock_items.length ? 'Stock may be available' : 'No stock available'}})</i></p>
                                                    <p v-if="expiryBool(drug.stop_date)" style="font-size: 1em;color: #E04F39;font-weight: bold"> Expiring in {{ formatExpiry(drug.stop_date) }} </p>
                                                    <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                                    <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                                    <p>{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                                    <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[drug.last_chart_administrations.length - 1].administered_at  : 'N/A'}}</b></p>
                                                    
                                                    <div style="display: flex; flex-wrap: wrap">
                                                        <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                            <p class="consideration-tag" style="width: fit-content" v-if="!hiddenTags.includes(tag.toUpperCase())">{{tag.toUpperCase()}}</p>
                                                        </div>
                                                    </div>
                                                    <p class="orange-button" style="width: fit-content" v-if="!drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')" @click="resupplyDrug(drug)">{{ !drug.loadingSupply ? 'REQ. RESUPPLY' : 'REQUESTING...'}}</p>
                                            <p class="green-outline-button" style="color: #00823c; border: 2px solid #00823c" v-if="drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')">RESUPPLY REQUESTED</p>
                                                </td>
                                                <td style="font-weight:bold; width: 5em">x {{drug.dose}}</td>
                                                <td style="font-weight:bold; ;" :style="drug.completed ? {width: '5em'}:{width: '10em'}">{{drug.route}}</td>
                                                <td v-if="!drug.completed && drug.removed"  style="width: 1em">
                                                    <SingleVerify v-if="drug.dose_unit!='Patch' && drug.dose_unit!='patch'" @submitIsCompleted="submitIsCompleted" :indices="i" :payload="submitPayload" @click="selectMedLoad(drug, true)"
                                                    endpoint="/administer" :status="(drug.status == 'N' || drug.status == 'R' || drug.status == 'W') ? 'danger': drug.status == 'approve'? 'good' : 'warning'" 
                                                    :warning="drug.status == 'N' ? 'Medication not available - Please notify prescriber': drug.status == 'R' ? 
                                                    'Resident Refused - Please notify prescriber': drug.status == 'W' ?
                                                    'Medication withheld for clinical reasons - Please notify prescriber':''" 
                                                    :dual="true" :checkIcon="true" :elemId="drug.chart_id+ '-approve'" />
                                                    <div v-else><i class="pi pi-check" :id="drug.chart_id+ '-approve'" @click="displayPatchAdminModal = true, selectMedLoad(drug, true), selectedPatch = drug"></i></div>
                                                </td>
                                                <td v-if="!drug.removed && !drug.completed" @click="()=>{removeDrugFromRegister(drug)}"  style="width: 1em">
                                                    <!-- <div ><i v-tooltip="'Select to remove from safe'" class="pi pi-eject" :style="drug.selectedForRemoval ? {backgroundColor: '#00AD50', color: 'white'}:{}"></i></div> -->
                                                    <SingleVerify
                                                        v-if="drug.stock_items && drug.stock_items.length"
                                                        @getDrugId="getDrugId"
                                                        :stock_items="drug.stock_items"
                                                        :payload="removeFromRegisterPayload" 
                                                        endpoint="/drug-safe-retrieval"
                                                        :drugSafeRemoval="true"
                                                        :dual="true"
                                                        :notes="true"
                                                        :id="drug.uuid"
                                                        :discardAvailable="discardAvailable"
                                                    />
                                                </td>
                                                <td v-if="!drug.completed" @click="displayDOCModal = true, selectMedLoad(drug, false)" style="width: 1em">
                                                    <div ><i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i></div>
                                                </td>
                                                
                                                <td v-if="(drug.completed || drug.doc) && !redoingAdministration[drug.chart_id]?.loading" colspan="2" style="display: flex; flex-direction: column; justify-content: end; align-items: center; margin-top: 7px;">
                                                    <div v-if="!drug.doc" class="administered" 
                                                        style=" display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 6em;
                                                        text-align: center;
                                                        padding-right: 10px;
                                                        border-radius: 6px;">
                                                        <!-- <i class="pi pi-check administered"></i>  -->
                                                        <p style="padding: 5px 10px; text-align: center;">DONE</p>
                                                    </div>
                                                    <div v-if="drug.doc && !redoingAdministration[drug.chart_id]?.loading" class="not-administered" 
                                                        style=" display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 6em;
                                                        text-align: center;
                                                        padding-right: 10px;
                                                        border-radius: 6px;
                                                        padding: 5px 10px;">
                                                        <!-- <i class="pi pi-times not-administered"></i>  -->
                                                        <p style=" text-align: center; white-space: nowrap">{{drug.doc.toUpperCase().slice(0,8)}}</p>
                                                    </div>
                                                    <!-- Error message if a redoingAdminChart action goes wrong. Check console for error details -->
                                                    <div v-if="redoingAdministration[drug.chart_id]?.error" style="color: red; font-size: 15px;">Error: Redo Failed.</div>
                                                </td>
                                                <td v-if="redoingAdministration[drug.chart_id]?.loading" style="width: 1em">
                                                    <div ><i class="pi pi-spin pi-spinner" :id="drug.chart_id+ '-redoing'"></i></div>
                                                </td>
                                                <td v-if="drug.doc && !drug.removed && !redoingAdministration[drug.chart_id]?.loading" @click="redoAdminChart(drug)" style="width: 1em">
                                                    <div>
                                                        <i class="pi pi-replay" :id="drug.chart_id+ '-redo'"></i>
                                                    </div>
                                                </td>
                                                <td style="width: 0em"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <!-- INSULIN MEDICATIONS -->
                                <div class="medication-group" v-if="getInsulin.length && !patient.displayPRN && !patient.displayNIM && !patient.displayPatch">
                                    <div class="yellow-background medication-group-header" 
                                    style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                        <h2 style="margin: 0;">Insulin Medications <span style="margin-left: 30px">({{getIncompleteInsulin.length}} Medication{{getIncompleteInsulin.length > 1 ? 's':''}} Requiring Action)</span></h2>
                                        <span class="tag" style="justify-self:end; width: auto;font-weight: 700; font-size: 15px; border-left: 40%;">
                                        <p style="color:#2c3e50;font-weight: 16px;padding: 0 0; margin: 0 0;" >Last BGL reading: {{patient.readings?.length ? patient.readings[0].value + " mmols/L at " +patient.readings[0].created_at : "N/A"}}</p>
                                        </span>
                                    </div>
                                    <div>
                                        <table style="border-collapse: collapse;"  >
                                            <tr class="insulin-medication-row" v-for="(drug, i) in getInsulin" :key="i">
                                                <td style="width: 10em"><div class="drug-image"><img src="../../assets/icons/medPlaceholder.png" alt=""></div></td>
                                                <td style="padding:10px;">
                                                    <p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>                                                     
                                                    <p v-if="expiryBool(drug.stop_date)" style="font-size: 1em;color: #E04F39;font-weight: bold"> Expiring in {{ formatExpiry(drug.stop_date) }} </p>
                                                    <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                                    <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                                    <p>{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                                            <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[drug.last_chart_administrations.length - 1].administered_at  : 'N/A'}}</b></p>
                                                    <div style="display: flex; flex-wrap: wrap">
                                                        <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                            <p class="consideration-tag" style="width: fit-content" v-if="!hiddenTags.includes(tag.toUpperCase())">{{tag.toUpperCase()}}</p>
                                                        </div>
                                                    </div>
                                                    <p class="orange-button" style="width: fit-content" v-if="!drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')" @click="resupplyDrug(drug)">{{ !drug.loadingSupply ? 'REQ. RESUPPLY' : 'REQUESTING...'}}</p>
                                            <p class="green-outline-button" style="color: #00823c; border: 2px solid #00823c" v-if="drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')">RESUPPLY REQUESTED</p>
                                                </td>
                                                <td style="font-weight:bold; width: 5em">x {{drug.dose}}</td>
                                                <td style="font-weight:bold; ;" :style="drug.completed ? {width: '5em'}:{width: '10em'}">{{drug.route}}</td>
                                                <td v-if="(getIncompleteInsulin.length != getInsulin.length) && !drug.completed"></td>
                                                <!-- <td @click="displayInsulinModal = true, selectInsulin(drug)"  style="width: 1em" v-if="(!lastBGLReading || lastBGLReading > 60) && !drug.completed">
                                                    <div class="administer-button">GET BGL</div>
                                                </td> -->

                                                <!-- ADMIN AND CROSS -->
                                                <td @click="displayInsulinModal = true, selectInsulin(drug)"  style="width: 1em;" v-if="!drug.completed">
                                                    <i class="pi pi-check"></i>
                                                </td>
                                                <td  v-if="!drug.completed" @click="displayDOCModal = true, selectMedLoad(drug, false)"  style="width: 1em">
                                                    <div>
                                                        <i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i>
                                                    </div>
                                                </td>
                                                
                                                <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" colspan="2" style="display: flex; flex-direction: column;" >
                                                    <div 
                                                        v-if="!drug.doc"
                                                        class="administered" 
                                                        style=" display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 6em;
                                                        text-align: center;
                                                        padding-right: 10px;
                                                        border-radius: 6px;">
                                                        <p style="padding: 5px 10px; text-align: center;">DONE</p>
                                                    </div>
                                                    <div
                                                        v-if="drug.doc && !redoingAdministration[drug.chart_id]?.loading"
                                                        class="not-administered" 
                                                        style="display: flex; align-items: center; width: 6em; text-align: center; padding-right: 10px; justify-content: center; border-radius: 6px; padding: 5px 10px;">
                                                        <!-- <i class="pi pi-times not-administered"></i>  -->
                                                        <p style=" text-align: center;">{{drug.doc.toUpperCase().slice(0,8)}}</p>
                                                    </div>
                                                    <!-- Error message if a redoingAdminChart action goes wrong. Check console for error details -->
                                                    <div v-if="redoingAdministration[drug.chart_id]?.error" style="color: red; font-size: 15px;">Error: Redo Failed.</div>
                                                </td>
                                                <td v-if="redoingAdministration[drug.chart_id]?.loading" style="width: 1em">
                                                    <div ><i class="pi pi-spin pi-spinner" :id="drug.chart_id+ '-redoing'"></i></div>
                                                </td>
                                                <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" @click="redoAdminChart(drug)" style="width: 1em">
                                                    <div ><i class="pi pi-replay" :id="drug.chart_id+ '-redo'"></i></div>
                                                </td>
                                                
                                                <td style="width: 0em"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <!-- NON PACKED MEDICATIONS -->
                                <div class="medication-group" v-if="getNonPacked.length && !patient.displayPRN && !patient.displayNIM && !patient.displayPatch">
                                    <div class="dark-grey-background medication-group-header" 
                                    style="display: flex; justify-content: space-between; padding: 10px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                        <h2 style="margin: 0;">Non-Packed Medications <span style="margin-left: 30px">({{getIncompleteNonPacked.length}} Medication{{getIncompleteNonPacked.length > 1 ? 's':''}} Requiring Action)</span></h2>
                                        <div style="display: flex; align-items: center" v-if="getIncompleteNonPacked.length">
                                            <h4 style="margin: 0;margin-left: 3.3em; color: white;">All</h4>
                                            <!-- <i class="pi pi-check" style="margin-left: 2.3em" @click="packedSubmit()"></i> -->
                                            <SingleVerify style="margin-left: 2.3em" @submitIsCompleted="submitIsCompleted" :payload="submitPayload" @click="mapNonpackedPayload(true)"
                                                endpoint="/administer-bulk" :checkIcon="true" :loop="true" :bulkAdminister="true"  />
                                            <i class="pi pi-times" style="margin-left: 1.4em; margin-right: 1.4em;" @click="displayDOCModal = true, mapNonpackedPayload(false)"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <table style="border-collapse: collapse;"  >
                                            <tr class="non-packed-medication-row" v-for="(drug, i) in getNonPacked" :key="i">
                                                <td v-if="mimsApiKey && loadMims" style="width: 10em">
                                                    <MimsImage
                                                        :alias_id="drug.dispensed_items?.length > 0 ? drug.dispensed_items[0].alias_id : drug.alias ? drug.alias.alias_id : ''"
                                                        off_market="true"
                                                        width="90px"
                                                        height="60px">
                                                    </MimsImage>
                                                </td>
                                                <td>
                                                    <!-- paracetamol 1 g powder for oral liquid, 1 sachet -->
                                                    <p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>
                                                    <p v-if="expiryBool(drug.stop_date)" style="font-size: 1em;color: #E04F39;font-weight: bold"> Expiring in {{ formatExpiry(drug.stop_date) }} </p>
                                                    <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                                    <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                                    <p style="font-size: 1em">{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                                            <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[drug.last_chart_administrations.length - 1].administered_at  : 'N/A'}}</b></p>
                                                    <div style="display: flex; flex-wrap: wrap">
                                                        <div v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                            <p class="consideration-tag" style="width: fit-content" v-if="!hiddenTags.includes(tag.toUpperCase())" >{{tag.toUpperCase()}}</p>
                                                        </div>
                                                    </div>
                                                    <p class="orange-button" style="width: fit-content" v-if="!drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')" @click="resupplyDrug(drug)">{{ !drug.loadingSupply ? 'REQ. RESUPPLY' : 'REQUESTING...'}}</p>
                                            <p class="green-outline-button" style="color: #00823c; border: 2px solid #00823c" v-if="drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')">RESUPPLY REQUESTED</p>
                                                </td>
                                                <td style="width: 5em; font-weight: bold">x {{drug.dose}}</td>
                                                <td style="width: 7em; font-weight: bold">{{drug.route}}</td>
                                                <!-- @click="submitPrimeSignature(drug, 'approve')"  -->
                                                <td v-if="(getIncompletePacked.length != getNonPacked.length) && !drug.completed"></td>
                                                <td v-if="!drug.completed" style="width: 1em">
                                                    
                                                    <SingleVerify v-if="drug.dose_unit != 'Patch' && drug.dose_unit != 'patch'" @submitIsCompleted="submitIsCompleted" :indices="i" :payload="submitPayload" @click="selectMedLoad(drug, true)"
                                                    endpoint="/administer" 
                                                    :dual="(drug.chart_type == 'Insulin Order' ||  drug.chart_type == 'Variable Dose') ? true: false" 
                                                    :checkIcon="true" :elemId="drug.chart_id+ '-approve'" />
                                                    <div v-else><i class="pi pi-check" :id="drug.chart_id+ '-approve'" @click="displayPatchAdminModal = true, selectMedLoad(drug, true), selectedPatch = drug"></i></div>
                                                    
                                                </td>
                                                <td v-if="!drug.completed" @click="displayDOCModal = true, selectMedLoad(drug, false)"  style="width: 1em">
                                                    <div>
                                                        <i class="pi pi-times" :id="drug.chart_id+ '-reject'"></i>
                                                    </div>
                                                </td>
                                                
                                                <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" colspan="2" style="display: flex; flex-direction: column;">
                                                    <div
                                                        v-if="!drug.doc"
                                                        class="administered"
                                                        style="display: flex; align-items: center; width: 6em; text-align: center; padding-right: 10px; justify-content: center; border-radius: 6px;">
                                                        <!-- <i class="pi pi-check administered"></i>  -->
                                                        <p style="padding: 5px 10px; text-align: center;">DONE</p>
                                                    </div>
                                                    <div
                                                        v-if="drug.doc"
                                                        class="not-administered" 
                                                        style="display: flex; align-items: center; width: 6em; text-align: center; padding-right: 10px; justify-content: center; border-radius: 6px; padding: 5px 10px;">
                                                        <!-- <i class="pi pi-times not-administered"></i>  -->
                                                        <p style=" text-align: center;">{{drug.doc.toUpperCase().slice(0,8)}}</p>
                                                    </div>
                                                    <!-- Error message if a redoingAdminChart action goes wrong. Check console for error details -->
                                                    <div v-if="redoingAdministration[drug.chart_id]?.error" style="color: red; font-size: 15px;">Error: Redo Failed.</div>
                                                </td>
                                                <td v-if="redoingAdministration[drug.chart_id]?.loading" style="width: 1em">
                                                    <div ><i class="pi pi-spin pi-spinner" :id="drug.chart_id+ '-redoing'"></i></div>
                                                </td>
                                                <td v-if="drug.completed && !redoingAdministration[drug.chart_id]?.loading" @click="redoAdminChart(drug)" style="width: 1em">
                                                    <div ><i class="pi pi-replay" :id="drug.chart_id+ '-redo'"></i></div>
                                                </td>
                                                <td style="width: 0em"></td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>



                                <!-- PRN MEDICATIONS -->
                                <div class="medication-group" v-if="patient.displayPRN">
                                    <div v-if="!loadingPRNs">
                                        <div v-for="(drug, i) in prns" :key="i" style="margin-bottom: 1em;">
                                            <div class="medication-group-header"  
                                            style="display: grid; grid-template-columns:30% 70%; align-items:center; background-color: rgb(0, 178, 162); padding: 10px 30px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                                <h2 style="margin: 0;">PRN</h2>
                                                <span class="tag" style="justify-self:end; width: auto;font-weight: 700; font-size: 15px; border-left: 40%;">
                                                    <span v-if="drug.amount_administered==drug.max_dose"><p style="color:#E04F39;font-weight: 16px;padding: 0 0; margin: 0 0;"> {{parseFloat(drug.amount_administered)}}/{{drug.max_dose ? drug.max_dose : 'null'}} {{drug.dose_unit}}{{ drug.max_dose && drug.max_dose > 1 ? 's' : ''}} administered in the last 24 hours</p></span>
                                                    <span style="color: #2c3e50;font-weight: 16px;" v-else><p style="font-weight: 16px;padding: 0 0; margin: 0 0;">{{parseFloat(drug.amount_administered)}}/{{drug.max_dose ? drug.max_dose : 'null'}} {{drug.dose_unit}}{{ drug.max_dose && drug.max_dose > 1 ? 's' : ''}} administered in the last 24 hours</p></span>
                                                </span>
                                            </div>
                                            <div v-if="drug.completed  && (drug.effective != true) && (drug.effective != false)" style="display: flex; justify-content: space-between; background-color: #E04F39; height: 45px; padding: 0 30px; align-items: center;">
                                                <p style="font-size: 16px; font-weight: bold;">{{drug.last_chart_administrations && drug.last_chart_administrations.length ? drug.last_chart_administrations[0].quantity : 'N/A'}} {{drug.dose_unit}}/s was administered at {{drug.last_chart_administrations && drug.last_chart_administrations.length ? drug.last_chart_administrations[0].administered_at : ''}}. Was this effective?</p>

                                                <span style="display: grid; grid-gap: 10px; grid-template-columns: 1fr 1fr">
                                                    <div ><i class="pi pi-check" @click="addEffective(1, drug)"></i></div>
                                                    <div ><i class="pi pi-times" @click="addEffective(0, drug)" ></i></div>
                                                </span>
                                            </div>
                                            <div>
                                                <table style="border-collapse: collapse;" >
                                                    <tr class="prn-medication-row">
                                                        <td v-if="mimsApiKey && loadMims" style="width: 10em">
                                                            <MimsImage
                                                                :alias_id="drug.dispensed_items?.length > 0 ? drug.dispensed_items[0].alias_id : drug.alias ? drug.alias.alias_id : ''"
                                                                off_market="true"
                                                                width="90px"
                                                                height="60px">
                                                            </MimsImage>
                                                        </td>
                                                        <td style="padding:10px"><p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>
                                                            <p v-if="expiryBool(drug.stop_date)" style="font-size: 1em;color: #E04F39;font-weight: bold"> Expiring in {{ formatExpiry(drug.stop_date) }} </p>
                                                            <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                                            <p v-if="drug.indication"><b>Indication:</b> {{drug.indication}}</p>
                                                            <p>{{drug.instructions.split('-------Please write any additional notes below this line-------').join('')}}</p>
                                                            <p style="font-size: 1em;padding-bottom: 5px;">Previous Event: <b>{{drug.last_chart_administrations && drug.last_chart_administrations.length ?  drug.last_chart_administrations[drug.last_chart_administrations.length - 1].administered_at  : 'N/A'}}</b></p>
                                                            <div style="display: flex; flex-wrap: wrap">
                                                                <div  v-for="(tag, tindex) in [... new Set(drug.chart_tags)]" style="margin-right: 5px" :key="tindex">
                                                                    <p class="consideration-tag" style="width: fit-content" v-if="!hiddenTags.includes(tag.toUpperCase())" >{{tag.toUpperCase()}}</p>
                                                                </div>
                                                            </div>
                                                            <p class="orange-button" style="width: fit-content" v-if="!drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')" @click="resupplyDrug(drug)">{{ !drug.loadingSupply ? 'REQ. RESUPPLY' : 'REQUESTING...'}}</p>
                                                            <p class="green-outline-button" style="color: #00823c; border: 2px solid #00823c" v-if="drug.requestedSupply && this.$store.state.user?.role?.permissions?.includes('can_do_resupply')">RESUPPLY REQUESTED</p>
                                                        </td>
                                                        <td style="width: 5em; font-weight: bold;">x {{drug.dose}}</td>
                                                        <td style="width: 10em; font-weight: bold;">{{drug.route}}</td>
                                                        <!-- @click="submitPrimeSignature(drug, 'approve')"  -->
                                                        <td style="width: 1em"></td>
                                                            <!-- <div ><i class="pi pi-check" :id="drug.chart_id+ '-approve'"></i></div> -->
                                                        <td   style="width: 1em" v-if="((drug.alias.tags.includes('S8') || drug.alias.tags.includes('s8')) || drug.stock_items?.length) && !drug.removed && !drug.completed">
                                                            <!-- <div class="administer-button">REMOVE FROM REG</div> -->
                                                            <div @click="displayPRNAdminModal = true, selectPRN(drug)" class="administer-button" v-if="(drug.stock_items?.length && drug.stock_items[0].quantity > 0)">REMOVE FROM REG</div>
                                                            <div class="administer-button" style="cursor: inherit; color: red" v-else>NO STOCK</div>
                                                        </td>
                                                        <td @click="displayPRNAdminModal = true, selectPRN(drug)"  style="width: 1em" v-if="((!drug.alias.tags.includes('S8') && !drug.alias.tags.includes('s8')) && (!drug.stock_items?.length || drug.removed)) && !drug.completed">
                                                            <div class="administer-button">ADMINISTER</div>
                                                        </td>
                                                        <td  style="width: 1em" v-if="drug.completed">
                                                            <div class="administer-button" style="cursor: inherit">COMPLETED</div>
                                                        </td>
                                                        <td style="width: 0em"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- NIM MEDICATIONS -->
                                <div class="medication-group" v-if="patient.displayNIM">
                                    <div v-if="!loadingNIMs">
                                        <div v-for="(drug, i) in nims" :key="i" style="margin-bottom: 1em;">
                                            <div class="medication-group-header"  
                                            style="display: flex; justify-content: flex-start; background-color: #274188; padding: 10px 30px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                                <h2 style="margin: 0;">NIM</h2>
                                                <span style=" font-weight: 700;
                                                font-size: 18px;
                                                line-height: 22px;
                                                margin-left: 15em;
                                                color: #274188;">{{parseFloat(drug.amount_administered).toFixed(2)}}/{{drug.max_dose ? drug.max_dose : 'null'}} {{drug.alias.form}}{{ drug.max_dose && drug.max_dose > 1 ? 's' : ''}} administered in the last 24 hours</span>
                                            </div>
                                            <div>
                                                <table style="border-collapse: collapse;" >
                                                    <tr class="nim-medication-row">
                                                        <td style="width: 10em"><div class="drug-image"><img src="../../assets/icons/medPlaceholder.png" alt=""></div></td>
                                                        <td style="padding:10px">
                                                            <p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>
                                                            <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                                            <p>{{drug.indication}}</p>
                                                            <p>{{drug.instructions}}</p>
                                                        </td>
                                                        <td style="width: 5em; font-weight: bold;">x {{drug.dose}}</td>
                                                        <td style="width: 10em; font-weight: bold;">{{drug.route}}</td>
                                                        <td style="width: 1em"></td>
                                                        <td @click="displayNIMAdminModal = true, selectNIM(drug)" v-if="!drug.completed" style="width: 1em" >
                                                            <div class="administer-button">ADMINISTER</div>
                                                        </td>
                                                        <td  style="width: 1em" v-if="drug.completed">
                                                            <div class="administer-button" style="cursor: inherit">COMPLETED</div>
                                                        </td>
                                                        <td style="width: 0em"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                        <div>
                                            <div v-if="loadingPRNs && patient.displayPRN">
                                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                                                <h4>Loading PRNs...</h4>
                                            </div>
                                            <div v-if="loadingNIMs && patient.displayNIM">
                                                <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
                                                <h4>Loading NIMs...</h4>
                                            </div>
                                            <div v-if="(prns && !prns.length) && !loadingPRNs && patient.displayPRN">
                                                <h3>No PRNs available for this resident.</h3>
                                            </div>
                                            <div v-if="(nims && !nims.length) && !loadingNIMs && patient.displayNIM">
                                                <h3>No NIMs available for this resident.</h3>
                                            </div>
                                        </div>

                                        <!-- PATCH MEDICATION -->
                                        <div class="medication-group" v-if="patient.displayPatch">
                                            <div>
                                                <div v-for="(drug, i) in patches" :key="i" style="margin-bottom: 1em;">
                                                    <div class="medication-group-header"  
                                                    style="display: flex; justify-content: flex-start; background-color: #EF9600; padding: 10px 30px; align-items: center; border-top-left-radius: 8px;border-top-right-radius: 8px;">
                                                        <h2 style="margin: 0;">PATCH</h2>
                                                        <span style="
                                                        font-weight: 700;
                                                        font-size: 18px;
                                                        line-height: 22px;
                                                        margin-left: 15em;
                                                        color: #EF9600;">{{parseFloat(drug.amount_administered).toFixed(2)}}/{{drug.max_dose ? drug.max_dose : 'null'}} {{drug.alias.form}}{{ drug.max_dose && drug.max_dose > 1 ? 's' : ''}} administered in the last 24 hours</span>
                                                    </div>
                                                    <div>
                                                        <table style="border-collapse: collapse;" >
                                                            <tr class="patch-medication-row">
                                                                <td style="width: 10em"><div class="drug-image"><img src="../../assets/icons/medPlaceholder.png" alt=""></div></td>
                                                                <td style="padding:10px">
                                                                    <p style="font-size: 1em; font-weight: bold;">{{drug.alias.name.charAt(0).toUpperCase() + drug.alias.name.slice(1)}}</p>
                                                                    <p style="font-size: 1em;"><b>Dispensed:</b> <i>{{drug.dispensed_items?.length ? drug.dispensed_items[0].name : 'No item dispensed'}}</i></p>
                                                                    <p>{{drug.indication}}</p>
                                                                    <p>{{drug.instructions}}</p>
                                                                    
                                                                </td>
                                                                <td style="width: 5em; font-weight: bold;">x {{drug.dose}}</td>
                                                                <td style="width: 10em; font-weight: bold;">{{drug.route}}</td>
                                                                <td style="width: 1em"></td>
                                                                <td @click="displayPatchAdminModal = true, selectPatch(drug)" v-if="(!drug.alias.tags.includes('S8') && !drug.alias.tags.includes('s8') || drug.removed) && !drug.completed" style="width: 1em" >
                                                                    <div class="administer-button">ADMINISTER</div>
                                                                </td>
                                                                <td @click="displayPatchAdminModal = true, selectPRN(drug)"  style="width: 1em" v-if="(drug.alias.tags.includes('S8') || drug.alias.tags.includes('s8')) && !drug.removed && !drug.completed">
                                                                    <div class="administer-button">REMOVE FROM REG</div>
                                                                </td>
                                                                <td  style="width: 1em" v-if="drug.completed">
                                                                    <div class="administer-button" style="cursor: inherit">COMPLETED</div>
                                                                </td>
                                                                <td style="width: 0em"></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            <div v-else style="margin: 0 auto; padding-top: 5em; display: flex; align-items: center;">
                <i class="pi pi-spin pi-spinner" v-if="loadingPatient || loadingResidentDrugs" style="font-size: 2rem !important;"></i>
                <div v-if="loadingPatient && !loadingResidentDrugs" style="text-align: center;">
                    <h2 style="color: black; margin: 0;" v-if="!loadingAdministration">{{noCharts ? 'No residents are due for administration at this location.' : 'No Location Selected'}}</h2>
                    <p style="font-size: 1.2em; margin: 0;" v-if="!loadingAdministration">Please select {{noCharts ? 'another':'a'}} location to view residents due for administration.</p>
                </div>
                <div v-if="loadingAdministration">
                    <h3 style="color: black; margin: 0;" >Waiting for patients to be retrieved from location/s.</h3>
                    <p style="font-size: 1.2em; margin: 0;">Please wait...</p>
                </div>
                
            </div>
            
        </div>
        <DOCModal v-if="displayDOCModal" @close="close" @receiveDOC="receiveDOC" :adminSubmission="submitPayload" :loop="loop" :selectedDrugName="selectedDrugName" />
        
        <PRNAdminModal v-if="displayPRNAdminModal" @completePRN="completePRN" @updatePRN="updatePRN" :selectedNIM="selectedNIM" :selectedPRN="selectedPRN" @close="close" @receiveDOC="receiveDOC" :adminSubmission="submitPayload"
        :patientId="patient && patient.uuid ? patient.uuid : ''"  @addInsulinReading="addInsulinReading" :readings="patient && patient.readings ? patient.readings : []"  />
        
        <NIMAdminModal v-if="displayNIMAdminModal" @completeNIM="completeNIM" :selectedNIM="selectedNIM" :selectedPRN="selectedPRN" @close="close" @receiveDOC="receiveDOC" :adminSubmission="submitPayload"
        :patientId="patient && patient.uuid ? patient.uuid : ''"  />
        <!--  :adminSubmission="submitPayload" -->
        <PatchAdminModal :adminSubmission="submitPayload" :adminRound="!selectedPatch.expired" @close="close" v-if="displayPatchAdminModal" @submitIsCompleted="submitIsCompleted" :selectedPatch="selectedPatch" :patientId="patient && patient.uuid ? patient.uuid : ''"  />
        
        <InsulinModal v-if="displayInsulinModal" @completeInsulin="completeInsulin" :patientId="patient && patient.uuid ? patient.uuid : ''" 
        @addInsulinReading="addInsulinReading" :readings="patient && patient.readings ? patient.readings : []" :selectedInsulin="selectedInsulin" @close="close" :adminSubmission="submitPayload" @submitIsCompleted="submitIsCompleted"  />
        <ExpiredAdminNoteModal v-if="displayExpiredNoteModal" @close="close" :selectedExpiredDrug="selectedExpiredDrug" :patientId="patient && patient.uuid ? patient.uuid : ''" />
    </div>
</template>

<script>
import SingleVerify from '@/components/verification/SingleVerify.vue';
import DOCModal from '@/components/modals/DOCModal.vue';
import PRNAdminModal from '@/components/modals/PRNAdminModal.vue';
import PatchAdminModal from '@/components/modals/PatchAdminModal.vue';
import NIMAdminModal from '@/components/modals/NIMAdminModal.vue';
import InsulinModal from '@/components/modals/InsulinModal.vue';
import ExpiredAdminNoteModal from '@/components/modals/ExpiredAdminNoteModal.vue';

import MimsImage from '../mims/MimsImage.vue';
import axios from 'axios';

export default{
    props:['spec_cons','loadMims','adminChart', 'patient', 'loadingPatient', 'emptyList', 'loadingAdministration', 'expiredCharts',
    'noCharts', 'prns', 'patches', 'loadingPRNs', 'prnMode', 'loadingNIMs', 'nims', 'hiddenTags', 'loadingResidentDrugs'],
    components:{
        SingleVerify,
        DOCModal,
        PRNAdminModal,
        PatchAdminModal,
        NIMAdminModal,
        InsulinModal,
        ExpiredAdminNoteModal,
        MimsImage
    },
    data(){
        return{
            displayInsulinModal: false, 
            mimsApiKey: localStorage.getItem('mimsApiKey'),
            displayPRNAdminModal: false,
            displayNIMAdminModal:false,
            displayPatchAdminModal:false,
            discardAvailable:false,
            displayExpiredNoteModal: false,
            // displayPRN: false,
            // displayNIM: false,
            loop:false,
            displayDOCModal: false,
            infoADRTab: 'INFO',
            removeFromSafe:[],
            selectedPRN:'',
            selectedNIM:'',
            selectedPatch:'',
            // removeFromRegisterPayload is the MAPPED payload of items within the removeFromSafe
            removeFromRegisterPayload:[],
            doseOmittedCode:[
              'A',
              'F',
              'H',
              'L',
              'S',
              'S/A',
              'V',
              'A/T',
              'C',
              'N',
              'N/R',
              'O',
              'R',
              'W',
              'W/R'
            ],
            packedPayload:[],
            NonpackedPayload:[],
            submitPayload: {},
            bulkItemsCompleted: 0,
            selectedDrugName: '',
            redoingAdministration: {},
            expiredMeds:null,
            expiredOptions:[
                {name:'Create Admin Note', value:'Note'}
            ],
            selectedExpiredOption:'',
            selectedExpiredDrug:''
        }
    },
    methods:{
        createExpiredNote(drug){
            this.selectedExpiredDrug = drug;
            this.displayExpiredNoteModal = false;
        },
        resupplyDrug(drug){
            console.log('this is the drug requesting to be resupplied', drug);
            drug.loadingSupply = true;
            
            axios.post('resupply/create', {prescription_id: drug.prescription_id}, this.$store.state.header).then(res=>{
                console.log('this is the res', res.data);
                drug.loadingSupply = false;
                drug.requestedSupply = true;
            }).catch(err=>{
                console.log('this is the resupply error', err);
                drug.loadingSupply = false;
                drug.requestedSupply = true;
                drug.alreadyRequested = true;
            })
        },
        expiryBool(date) {
            const targetDate = new Date(date)
            console.log(targetDate)
            const currentDate = new Date();
            const timeDifference = targetDate.getTime() - currentDate.getTime();
            // Convert milliseconds to days
            const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
            if (daysDifference <= 14) {
                return true
                } else {
                return false
                }
        },
        formatExpiry(date) {
            const targetDate = new Date(date)
            const currentDate = new Date();
            const timeDifference = targetDate.getTime() - currentDate.getTime();
            // Convert milliseconds to days and hours remaining
            const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
            const truncatedDaysDifference = Math.trunc(daysDifference);
            // we use ceiling value to prevent displaying 0 days and 0 hours when hoursDiff is < 0.5 but > 0
            const hoursDifference = Math.ceil((daysDifference - truncatedDaysDifference) * 24);
            const template = `
                ${truncatedDaysDifference} 
                ${((truncatedDaysDifference === 1 ? 'day' : 'days'))} 
                and ${hoursDifference} ${(hoursDifference === 1 ? 'hour' : 'hours')}
                `;
            return template; // displays as X day/s and Y hour/S
        },
        showPatch(){
            this.$emit('togglePatch', true);
            setTimeout(()=>{
                if(this.patient.displayPatch){
                    console.log('calling the retrieve in selected')
                    this.retrievePatch()
                }
            },100)
            
        },
        showPRN(){
            this.$emit('togglePRN', true);
            setTimeout(()=>{
                if(this.patient.displayPRN){
                    this.retrievePRN()
                } 
                // else{
                //     this.$emit('retrieveExpired')
                // }
            },100)
            
        },
        showNIM(){
            this.$emit('toggleNIM', true);
            setTimeout(()=>{
                if(this.patient.displayNIM){
                    this.retrieveNIM()
                }
            },100)
            
        },
        addEffective(effective, drug){
            
            this.$emit('submitEffective', {
                effective: effective,
                prn_id: drug.administration_id
            });
        },
        close(value){
            if(value){
                this.displayDOCModal = false;
                this.displayPRNAdminModal = false;
                this.displayNIMAdminModal = false;
                this.displayInsulinModal = false;
                this.displayPatchAdminModal = false;
                this.displayExpiredNoteModal = false; 
            }
        },
        retrievePRN(){
            this.$emit('retrievePRNs', true);
        },
        retrieveNIM(){
            this.$emit('retrieveNIMs', true);
        },
        retrievePatch(){
            this.$emit('retrievePatches', true);
        },
        selectPRN(drug){
            this.selectedPRN = drug;
            if(this.selectedPRN.stock_items && this.selectedPRN.stock_items.length){
                this.selectedPRN.stock_items = this.selectedPRN.stock_items.filter((item, index) => this.selectedPRN.stock_items.indexOf(item) === index);
            }
            
            this.selectMedLoad(drug, false);
        },
        selectNIM(drug){
            this.selectedNIM = drug;
            this.selectMedLoad(drug, false);
        },
        selectPatch(drug){
            this.selectedPatch = drug;
            this.selectMedLoad(drug, false);
        },
        selectInsulin(drug){
            this.selectedInsulin = drug;
            this.selectMedLoad(drug, false);
        },
        getDrugId(value){
            if(value){
                console.log('this is the drug containing drug retrieval id', value);
                if(value.id){
                    this.adminChart.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.removed = true;
                            chart.drug_safe_retrieval = value.drug_safe_retrieval;
                        }
                    })
                    setTimeout(()=>{
                        this.close(true);
                    },200)
                }
            }
        },
        updatePRN(value){
            if(value){
                console.log('this is the drug containing drug retrieval id', value);
                if(value.id){
                    this.prns.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.removed = true;
                            chart.drug_safe_retrieval = value.drug_safe_retrieval;
                        }
                    })
                }
            }
        },
        updateNIM(value){
            if(value){
                console.log('this is the drug containing drug retrieval id', value);
                if(value.id){
                    this.nims.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.removed = true;
                            chart.drug_safe_retrieval = value.drug_safe_retrieval;
                        }
                    })
                }
            }
        },
        addInsulinReading(value){
            if(value){
                console.log('this is the insulin update (reading)', value);
                this.$emit('updatePatientReading', value);
                this.displayInsulinModal = false;
            }
        },
        completePRN(value){
            if(value){
                console.log('this is the drug completed prn', value);
                this.$emit('completePRN', value);
            }
        },
        completeNIM(value){
            if(value){
                console.log('this is the drug containing drug retrieval id, NIM', value);
                this.$emit('completeNIM', value);
                if(value){
                    this.nims.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.completed = true;
                            this.$emit('completedNIM', value);
                        }
                    })
                } 
            }
        },
        completeInsulin(value){
            if(value){
                console.log('this is the drug containing insulin', value.uuid);
                this.$emit('isCompleted',{item:value.uuid} );
                this.adminChart.forEach(chart=>{
                    if(chart.uuid == value.uuid){
                        chart.completed = true;
                        chart = {
                            latest_administration: {
                                uuid: value?.response.uuid,
                                quantity: value?.quantity.toString()
                            },
                            ...chart
                        };
                        // fallback incase component is destroyed and this.adminChart resets
                        localStorage.setItem(chart.chart_id, value?.response?.uuid);
                    }
                })
            }
        },
        receiveDOC(value){
            console.log('THIS IS THE DOC RECEIVED', value);
            if(value){
                if(value.bulk_meds){
                    this.adminChart.forEach(chart=>{
                        value.bulk_meds.forEach((item, index)=>{
                            console.log("bulk meds from sig response");
                            console.log(value);
                            console.log('this is the chart uuid', chart.uuid, item.calendar_item_id, index);
                            if(chart.uuid == item.calendar_item_id){
                                console.log('match this');
                                chart.doc = item?.doc;
                                chart.completed = true;
                                chart = {
                                    latest_administration: item?.response,
                                    ...chart
                                };
                                chart = {
                                    latest_administration: item?.response,
                                    ...chart
                                };
                                this.$emit('isCompleted', {item: chart.uuid});
                                // fallback incase component is destroyed and this.adminChart resets
                                localStorage.setItem(chart.chart_id, item?.response?.uuid);
                            }
                        })
                    })
                } else{
                    this.adminChart.forEach(chart=>{
                        if(chart.uuid == value.id){
                            chart.doc = value.doc.value;
                            chart.completed = true;
                            chart = {
                                latest_administration: value?.response,
                                ...chart
                            };
                            chart = {
                                latest_administration: value?.response,
                                ...chart
                            };
                            this.$emit('isCompleted', {item: chart.uuid});
                            // fallback incase component is destroyed and this.adminChart resets
                            localStorage.setItem(chart.chart_id, value?.response?.uuid);
                        }
                    })
                }
                
            }
        },
        removeDrugFromRegister(drug){
            let notes = "";
            if(drug.dose_unit.toLowerCase() != drug.container_unit.toLowerCase()){
                notes = "Administering " + drug.dose + " " + drug.dose_unit + "/s"; 
            }

            let qty = parseFloat(drug.dose_container_amount ?? drug.dose);
            let ceilingQty = Math.ceil(qty);
                
            let discard_amount = parseFloat(ceilingQty - qty).toFixed(2);
                
            if(discard_amount > 0){
                console.log("discard amount is greater than 0: " + discard_amount);
            }else{
                console.log("discard amount is not greater than 0: " + discard_amount);
            }
            

            this.removeFromRegisterPayload = 
                {
                    prescriber_uuid:drug.prescriber_id,
                    stock_item_id:drug.stock_items[0].uuid,
                    quantity: qty.toString(),
                    patient_id: this.patient.uuid,
                    dose_quantity: drug.dose,
                    dose_unit: drug.dose_unit,
                    notes: notes,
                    ...((discard_amount > 0) && { discard_amount: discard_amount.toString() }), // conditionally adds discard_amount to payload
                    
                }
            this.discardAvailable = discard_amount > 0 ? discard_amount + " " + drug.container_unit + "/s" : false;
            
        },
        mapPackedPayload(successfulAdministration){
            console.log('calling map packed payload function', successfulAdministration)
            this.packedPayload = [];
            this.loop = true;
            this.getIncompletePacked.forEach(med=>{
                this.packedPayload.push(
                    {
                        administered: successfulAdministration,
                        drug_safe_retrieval: '',
                        calendar_item_id:med.uuid,
                        chart_id: med.chart_id,
                        doc:'',
                        dose: med.dose,
                        note:'',
                        drug: med.alias.name,
                        dose_unit: med.dose_unit
                    }
                )
            });
            this.submitPayload = {
                doc: null,
                bulk_meds: this.packedPayload
            };
        },
        mapNonpackedPayload(successfulAdministration){
            console.log('calling map unpacked payload function', successfulAdministration)
            this.NonpackedPayload = [];
            this.loop = true;
            this.getIncompleteNonPacked.forEach(med=>{
                this.NonpackedPayload.push(
                    {
                        administered: successfulAdministration,
                        drug_safe_retrieval: '',
                        calendar_item_id:med.uuid,
                        chart_id: med.chart_id,
                        doc:'',
                        dose: med.dose,
                        note:'',
                        drug: med.alias.name,
                        dose_unit: med.dose_unit
                    }
                )
            });
            this.submitPayload = {
                doc: null,
                bulk_meds: this.NonpackedPayload
            };
        },
        selectDOC(drug){
            drug.status = document.getElementById(`${drug.chart_id}-doc-admin`).value;
            console.log('this is the drug doc and object', drug.status, drug);
        },
        redoAdminChart(chart){
            console.log('CHART', chart);
            let currentChart = this.adminChart.filter((item) => {
                return chart.chart_id == item.chart_id;
            });
            let administration_id = chart.latest_administration?.uuid || currentChart[0]?.latest_administration?.uuid || localStorage.getItem(chart.chart_id);
            let updateAdminPayload = {
                administration_id
            };
            console.log('this is the payload to send to /update-administration-event', updateAdminPayload);
            
            // begin redoing of administration event
            this.redoingAdministration[chart.chart_id] = {
                loading: true,
                error: false
            };

            axios.post("/update-administration-event", updateAdminPayload, this.$store.state.header).then(res => {
                console.log('this is the res from endpoint: /update-administration-event', res);
                // final state for redo: tick and cross buttons restored
                this.redoingAdministration[chart.chart_id] = {
                    loading: false,
                    error: false
                };
                // reset the chart item in the FE for current session of activity
                chart.completed = false;
                chart.doc = '';
            }).catch(err => {
                console.error('this is the error from endpoint: /update-administration-event', err);
                this.redoingAdministration[chart.chart_id] = {
                    loading: false,
                    error: true
                };
            });
        },
        submitPrimeSignature(drug, status){
            this.$emit('primeSignature', drug, status);
        },
        submitIsCompleted(value){
            console.log('RECEIVED THIS FROM SUBMIT IS COMPLETED', value);
            
            if(value){
                if(value.bulk_meds){
                    this.adminChart.forEach(chart=>{
                        value.bulk_meds.forEach((item, index)=>{
                            console.log('this is the chart uuid', chart.uuid, item.calendar_item_id, index);
                            if(chart.uuid == item.calendar_item_id){
                                this.$emit('isCompleted', {item: chart.uuid});
                                console.log('match this');
                                chart.completed = true;
                                // reference administration id for any redo operation
                                chart = {
                                    latest_administration: item?.response,
                                    ...chart
                                };
                                // fallback incase component is destroyed and this.adminChart resets
                                localStorage.setItem(chart.chart_id, item?.response?.uuid);
                            }
                        })
                    })
                } else{
                    this.$emit('isCompleted', value);
                    this.adminChart.forEach(chart=>{
                        console.log('this is the chart uuid', chart, chart.calendar_item_id, value.item);
                        if(chart.uuid == value.item){
                            console.log('match this');
                            chart.completed = true;
                            // reference administration id for any redo operation
                            chart = {
                                latest_administration: value?.response,
                                ...chart
                            };
                            // fallback incase component is destroyed and this.adminChart resets
                            localStorage.setItem(chart.chart_id, value?.response?.uuid);
                        }
                    })
                }
                
            }
            this.removeFromSafe = [];
        },
        updateRemovalList(drug){
            drug.selectedForRemoval = !drug.selectedForRemoval; 
            if(!drug.selectedForRemoval){
                this.removeFromSafe.splice(this.removeFromSafe.indexOf(drug), 1)
            } else{
                this.removeFromSafe.push(drug);
            }
            console.log('this is the remove from safe list', this.removeFromSafe);
            this.mapRemoveFromRegisterPayload();
        },
        formatNotesForSignOff(drug, administered) {
                let notes;
                const doseContainerQty = (drug) => {
                    let containerConstant = drug?.container_constant ?? 1;
                    let myQty = parseFloat(parseFloat(drug.dose) / containerConstant);
                    return myQty.toFixed(2);
                };
                if (administered) {
                    let date = new Date();
                    notes = `Dose administered.
Date: ${date.toLocaleDateString('en-AU')}
Time of administration: ${date.toLocaleTimeString('en-AU')}
Drug name: ${drug.alias.name}
Dose: ${parseFloat(drug.dose).toFixed(2)} ${drug.dose_unit.toLowerCase()}/s ${(drug.container_unit.toLowerCase() === 'ampoule' && drug.container_unit.toLowerCase() !== drug.dose_unit.toLowerCase()) ? '[' + doseContainerQty(drug) + ' Ampoule(s)' + ']': ''}
Administration Route: ${drug.route}
                    `;
                } else notes = '';
                return {
                    notes: notes
                };
        },
        selectMedLoad(drug, administered){
            this.loop = false;
            this.selectedDrugName = drug.alias.name;
            if(!drug.expired){
                this.submitPayload = {
                    drug: drug,
                    administered: administered,
                    drug_safe_retrieval: drug.drug_safe_retrieval ? drug.drug_safe_retrieval : '',
                    calendar_item_id: drug.uuid ? drug.uuid : 'no calendar id',
                    chart_id: drug.chart_id ? drug.chart_id : drug.uuid,
                    patch_site: null,
                    doc:'',
                    dose: parseFloat(drug.dose).toFixed(2),
                    notes: this.formatNotesForSignOff(drug, administered).notes,
                    reading: '',
                } 
            } else{
                this.submitPayload = {
                    administered: administered,
                    drug_safe_retrieval: drug.drug_safe_retrieval ? drug.drug_safe_retrieval : '',
                    calendar_item_id: null,
                    chart_id: drug.uuid,
                    patch_site: null,
                    doc:'',
                    dose: parseFloat(drug.dose).toFixed(2),
                    reading: '',
                }
            }
        },
        getLastEvent(lastChartAdmin) {
            let adminAt = "N/A";

            if (lastChartAdmin && lastChartAdmin.length) {
                adminAt = lastChartAdmin.reduce((latestDate, admin) => {
                    const administeredDate = new Date(admin.administered_at);
                    
                    if (!latestDate || administeredDate > new Date(latestDate)) {
                        return admin.administered_at;
                    } else {
                        return latestDate;
                    }
                }, null);
            }

            return adminAt;
        }
    },
    mounted(){
    },
    computed:{
        uniqueSpecCons(){
            let b = this.spec_cons &&  this.spec_cons.special_considerations ?  this.spec_cons.special_considerations : [];
            const set = new Set();
            const result = b.filter((o) => {
                if (set.has(o.name)) return false;
                set.add(o.name);
                return true;
            });
            return result;
        },
        sortedPacked(){
            let b = [...this.getPacked];
            return b.sort((a,b) => {
                if(a.completed < b.completed) return -1;
                if(a.completed > b.completed) return 1;
                return 0;
            });
        },
        getIncompleteInsulin(){
            if(this.adminChart){
                return this.adminChart.filter(item=>item.chart_type == 'insulin' && !item.completed);
            }   else{
                return []
            }
        },
        lastBGLReading(){
            if(this.patient.readings.length){
                let lastReading = new Date(this.patient.readings[0].created_at);
                let currentTime = new Date();
                lastReading = lastReading.getTime()
                currentTime = currentTime.getTime()
                let timeDiff = (currentTime - lastReading)/1000/60;
                return timeDiff
            } else{
                return null
            }
        },
        getInsulin(){
            if(this.adminChart){
                return this.adminChart.filter(item=>item.chart_type == 'insulin');
            } else{
                return []
            }
            
        },
        getPacked(){
            if(this.adminChart){
                return this.adminChart.filter(item=>item.chart_tags.includes('packed'));
            } else{
                return []
            }
            
        },
        getIncompletePacked(){
            if(this.adminChart){
                return this.adminChart.filter(item=>item.chart_tags.includes('packed') && !item.completed)
            } else{
                return []
            }
           
        },
        getCompletePacked(){
            if(this.adminChart){
                return this.adminChart.filter(item=>item.chart_tags.includes('packed') && item.completed)
            } else{
                return []
            }
            
        },
        getNonPacked(){
            if(this.adminChart){
                return this.adminChart.filter(item=>(!item.chart_tags.includes('packed') && (!item.alias.tags.includes('S8') && !item.alias.tags.includes('s8') && (!item.stock_items || !item.stock_items.length )) && item.chart_type != 'insulin'));
            } else{
                return []
            }
            
        },
        getIncompleteNonPacked(){
            if(this.adminChart){
                return this.adminChart.filter(item=>(!item.chart_tags.includes('packed') && (!item.alias.tags.includes('S8') && !item.alias.tags.includes('s8') && (!item.stock_items || !item.stock_items.length )) && !item.completed && item.chart_type != 'insulin'));
            } else{
                return []
            }
            
        },
        getIncompleteS8(){
            if(this.adminChart){
                let filtered = this.adminChart.filter(item=>!item.chart_tags.includes('packed') && (item.alias.tags.includes('S8') || item.alias.tags.includes('s8') || item.alias.tags.includes('S11') || item.alias.tags.includes('s11') || (item.stock_items && item.stock_items.length)) && !item.completed);
                return filtered;
            } else{
                return []
            }
        },
        getS8(){
            if(this.adminChart){
                let filtered = this.adminChart.filter(item=>!item.chart_tags.includes('packed') && (item.alias.tags.includes('S8') || item.alias.tags.includes('s8') || item.alias.tags.includes('S11') || item.alias.tags.includes('s11') || (item.stock_items && item.stock_items.length)));
                filtered.forEach(chart=>{
                    // chart.removed = false;
                    chart.selectedForRemoval = false;
                });
                return filtered;
            } else{
                return []
            }
        },
        displayExpiredCharts() {
            if(this.patient.displayNIM) {
                return false;
            }
            return true;
        },
    }
}
</script>

<style lang="scss" scoped>

.dummy-icon{
    width: 50px !important;
}
.administered{
    background-color: #00AD50 !important;
    color: white !important;
    p{
        margin: 0; 
        padding: 0; 
        font-weight: bold; 
        width: fit-content
    }
}

.not-administered{
    background-color: #E04F39 !important;
    color: white !important;
    p{
        margin: 0; 
        padding: 0; 
        font-weight: bold; 
        width: fit-content
    }
}
.medication-group-header{
    h2{
        font-size: 18px;
    }
    span{
        font-size: 18px;
    }
}

.medication-group{
    table{
        td{
            font-size: 16px;
            p{
                margin: 0;
            }
        }
    }
}
.admin-chart-header{
    display: flex;
    padding: 1em;
    padding-top: 0;
}

.drug-image{
    // height: 75px;
    // width: 75px;
    // background-color: white;
    padding-left: 15px;
    img{
        max-height: 100px;
        max-width:120px;
        width: auto;
        height: auto;
    }
}

.administer-button{
    box-sizing: border-box;
    width: 187px;
    background: #FFFFFF;
    border: 2px solid #000000;
    border-radius: 6px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}
.grey-field{
    background-color: #f3f4f4 !important;
    border: none !important;
}

.four-table-row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.four-column-entry{
    width: 22%;
    min-width: 120px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.two-column-row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.two-column-entry{
    width: 45%;
    min-width: 200px;
    margin-right: 10px;
}

.medication-group{
    // margin-top: 10px;
    margin-bottom: 10px;
    h2{
        color: white;
        width: fit-content;
    }
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.packed-medication-row{
     background-color: rgba(8, 112, 220, 0.2);  
     td{
         padding: 10px;
     }   
}

.scheduled-medication-row{
     background-color: rgba(224, 79, 57, 0.2);
     td{
         padding: 10px;
     }   
}

.insulin-medication-row{
    background-color: #FFE67C;
     td{
         padding: 10px;
     }   
}

.non-packed-medication-row{
     background-color: #F3F4F4;
     td{
         padding: 10px;
     }   
}

.prn-medication-row{
    background-color: #94D6D0;
    td{
        padding: 10px;
    }
}

.nim-medication-row{
    background-color: rgba(39, 65, 136, 0.35);
    td{
        padding: 10px;
    }
}

.patch-medication-row{
    background-color: #FED182;
    td{
        padding: 10px;
    }
}




.pi-check, .pi-times, .pi-sign-out, .pi-lock-open, .pi-eject, .pi-replay{
    background-color: white;
    padding: 10px;
    font-weight: bold;
    border-radius: 8px;
    color: grey;
    cursor: pointer;
}



.tag {
  pointer-events: none;
  background-color: white;
  border: none;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 10px;
}

</style>
