<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div v-if="!submitLoading" class="modal-container" style="min-width: 33.25rem; width: 33.25rem">
                <div class="modal-header">
                    <slot name="header">
                        <div>
                            <h3 class="form-header">{{ endpoint == '/drug-safe-retrieval' || (drugReg && drugReg.length) ? 'Remove from Register' : 'Signature \
                            Verification'}}</h3>
                        </div>
                        <!-- $emit(isSuccessful, true) -->
                        <button @click="closeModal()" class="red-close-button"
                            style="width: 30px !important">
                            <i class="pi pi-times" style="padding: 0"></i>
                        </button>
                    </slot>
                </div>
                <div class="modal-body" style="padding-bottom: 20px; text-align: left;">
                    <slot name="body">
                        <!-- WARNING -->
                        <div v-if="warning"
                            style="color: #E04F39; width: fit-content; margin: 0 auto; margin: 1em auto; display: flex; align-items: center;">
                            <i class="pi pi-exclamation-circle" style="font-size: 2em !important"></i>
                            <h4 style="margin: 0; font-weight: normal">{{ warning }}</h4>
                        </div>
                        <!-- WARNING -->
                        <p style="margin-left: 20px;">Note: Closing this modal will not save any signatures or notes.</p>
                        
                        

                        <!-- SIGN WARNING  -->
                        <p style="color: red; font-weight: 500; font-size: 1em;text-align: center; margin: 0"
                            v-if="sig2.name && sig1.name && (sig2.name == sig1.name)">{{ signError }}</p>
                        <p style="color: red; font-weight: 500; font-size: 1em;text-align: center; margin: 0" v-if="errMessage">{{ errMessage.message ? errMessage.message.slice(0, 40) : errMessage }}
                        </p>
                        <p style="color: red; font-weight: 500; font-size: 1em;text-align: center; margin: 0" v-if="errMessage2">{{ errMessage.message ||  errMessage?.errors ?  (errMessage?.message ? errMessage?.message + ' ' : '') + (errMessage?.errors ? Object.values(errMessage?.errors).flat()[0] : '') : errMessage }}
                        </p>
                        <!-- <p style="color: orange; font-weight: 500; font-size: 1em; margin: 0" v-if="errMessage && saveContext">Draft has
                            been saved.</p> -->
                        <!-- <p v-if="errMessage">{{errMessage}}</p> -->
                        <!-- IF STOCK ITEMS AVAILABLE -->
                        <div v-if="stock_items" style="padding-left: 20px;">
                            <!-- IF SINGLE ITEM -->
                            <p style="font-size: 1em;margin-top: 2px;margin-bottom: 2px" v-if="stock_items.length == 1"><b>Dispensed drug:</b> {{stock_items[0]?.alias?.name }}</p>
                            <p style="font-size: 1em;margin-top: 2px;margin-bottom: 2px" v-if="stock_items.length == 1"><b>Retrieving:</b> {{payload?.quantity}} {{stock_items[0]?.alias?.form}}/s <span v-if="payload?.dose_unit && stock_items[0]?.alias?.form?.toLowerCase() != payload?.dose_unit?.toLowerCase()">({{ payload?.dose_quantity }} {{ payload?.dose_unit }}/s)</span></p>
                            <p style="font-size: 1em;margin-top: 2px;margin-bottom: 2px" v-if="stock_items.length == 1"><b>Remaining balance:</b> {{(stock_items[0]?.quantity - payload?.quantity).toFixed(2)}} {{stock_items[0]?.alias?.form}}/s</p>
                            
                            <!-- IF MULTIPLE ITEMS -->
                            <div v-else style="display: flex; flex-direction: column; align-items: baseline; gap: 10px;">
                                <p style="font-size: 1em; margin: 0;"><b>Confirm dispensed drug: </b></p>
                                <Dropdown v-model="selected_stock_item" :options="stock_items" optionLabel="name"
                                @change="replaceStockItemId()"
                                :placeholder="selected_stock_item ? selected_stock_item.name : 'Select drug'" />
                                <p style="font-size: 1em;margin-top: 2px;margin-bottom: 2px" v-if="selected_stock_item"><b>Dispensed drug:</b> {{ selected_stock_item?.alias?.name }}</p>
                                <p style="font-size: 1em;margin-top: 2px;margin-bottom: 2px" v-if="selected_stock_item"><b>Retrieving:</b> {{ payload?.quantity }} {{ selected_stock_item?.alias?.form }}/s <span v-if="payload?.dose_unit && selected_stock_item?.alias?.form?.toLowerCase() != payload?.dose_unit?.toLowerCase()">({{ payload?.dose_quantity }} {{ payload?.dose_unit }}/s)</span></p>
                                <p style="font-size: 1em;margin-top: 2px;margin-bottom: 2px" v-if="selected_stock_item"><b>Remaining balance:</b> {{ (selected_stock_item?.quantity - payload?.quantity).toFixed(2) }} {{ selected_stock_item?.alias?.form }}/s</p>
                                
                            </div>
                            <p style="margin-left: 20px; font-size: 1em;" v-if="discardAvailable">
                                Would you like to discard unused drug? ({{ discardAvailable }}) <input type="checkbox" style="height:1em; min-width: 5px;" class="discard-checkbox" v-model="discardRemaining" />
                            </p>
                        </div>
                        
                        <!-- SIGN WARNING  -->
                        <p style="margin: 0; margin-left: 20px; color:red; text-align: center">Please use PIN if set.</p>
                        <form class="action-modal" style="padding-top: 0">
                            
                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div v-if="!noAuthRequired" class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px; width: 100%; padding-left: 0px;">
                                <!-- v-if="notes" -->
                                <div v-if="endpoint?.includes('administ')">
                                    <div v-if="!bulkAdminister">
                                        <!-- <Textarea v-tooltip="'Notes'" style="width: 100%; min-height: 10em;" v-if="!toSubmit.notes"
                                        placeholder="Typing any Notes here will be saved" :autoResize="true" v-model="verification.notes" /> -->
                                        <Textarea v-tooltip="'Notes'" style="width: 100%; min-height: 10em;"
                                            placeholder="Typing any Notes here will be saved" :autoResize="true" v-model="getNotes" />
                                    </div>
                                    <Textarea v-tooltip="'Notes'" style="width: 100%; min-height: 5em;" v-if="displayReason"
                                        placeholder="Enter a reason here." :autoResize="true" v-model="reason" />
                                </div>
                                <div v-if="drugReg && drugReg.length">
                                    <p style="text-align: left;">You are removing the following drugs from the register:
                                    </p>
                                    <table style="border-collapse: collapse">
                                        <tr v-for="(item, index) in drugReg" :key="index" class="drug-entry-transfer">
                                            <td style="width: 50%;">
                                                <p>{{ item.stock }}</p>
                                            </td>
                                            <td>
                                                <p>{{ item.qty }} {{ item.form }}/s</p>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div v-if="(manual_loop && payloads) || displaySaveContext">
                                    <div v-for="(value, key) in saveContext" :key="key" style="text-align: left; margin-top: -10px;" >
                                        <p v-if="key != 'doubleSig' && key != 'drugs' && key != 'supplemental' && key != 'patient'" style="font-size: 1em;"><span style="font-weight: bold">{{ key.slice(0,1).toUpperCase() }}{{ key.split('_').join(' ').slice(1) }}</span> - {{ value }}</p>
                                    </div>
                                </div>
                                <div style="text-align: left; background-color: #f3f4f4; padding: 0 10px;" v-if="manual_loop && payloads">
                                    <p style="font-size: 1em;">Medication being signed off ({{ payloadsIndex+1 }} of {{payloads.length}}):</p>
                                    <p style="font-size: 1em;" v-if="payloads[payloadsIndex].drugs">- {{payloads[payloadsIndex].drugs[0].alias}} x <b>{{ payloads[payloadsIndex].drugs[0].qty }} </b></p>
                                    <p style="font-size: 1em;" v-else>- {{payloads[payloadsIndex].stock_items[0].alias}} x <b>{{ payloads[payloadsIndex].stock_items[0].qty }} </b></p>
                                </div>
                                <div
                                    style="margin-top: 10px;display: grid; justify-content: space-around; margin: 0 auto;" 
                                        :style="
                                            ((this.forceSingleSig === undefined ? true : (this.forceSingleSig ? false : true)) && 
                                            ((this.dualForMeds || this.dual) || 
                                            endpoint =='/drug-safe-retrieval' || 
                                            endpoint =='/stock-take-submit')) ? {gridTemplateColumns: '1fr 1fr', gridGap: '10px', width: '100%'}:{}">
                                    <!-- && !singleSig -->
                                        <div style="display: grid; grid-gap: 10px;" v-if="(displayReason ? (reason ? true : false) : true) && !loading1 && !success1 && onesignature()">
                                        <h4 style="margin: 0; padding-left: 15px;text-align: left">Signature {{ (this.dualForMeds || this.dual) && !witness && ($store.state.user.role.permissions.includes('signature_primary') || $store.state.user.role.permissions.includes('signature_witness')) ? '1' :
                                         ''
                                        }}
                                        </h4>
                                        <input
                                            v-on:keyup.enter="verifySig(1)"
                                            ref="sig1username"
                                            type="text"
                                            v-model="sig1.name"
                                            placeholder="Username..."
                                        >
                                        <input
                                            v-on:keyup.enter="verifySig(1)"
                                            ref="sig1password"
                                            type="password"
                                            v-model="sig1.password"
                                            :placeholder="success1 ? 'Signed' : 'PIN/Password...'"
                                            autocomplete="one-time-code"
                                        >
                                        <div class="green-button"
                                            @click="verifySig(1)">
                                            {{success1 ? 'Signed' : 'Sign'}}
                                        </div>
                                    </div>
                                    <div v-if="!success1 && loading1">
                                        <i class="pi pi-spin pi-spinner"
                                            style="font-size: 2rem !important;margin: 0 auto; margin-top: 2em;"></i>
                                    </div>
                                        <div v-if="success1 && onesignature()" style="display: flex; align-items: center;width: 100%;justify-content: center;height: 147px">
                                        <h4
                                            style="color: green; margin: 0; border: 2px solid green; padding: 5px 10px; border-radius: 5px">
                                            {{ prescriber ? "Prescriber" : "Signature 1" }} signed.</h4>
                                            <!-- <img :src="require('@/assets/icons/checkIcons.png')"
                                                alt="checkimage"
                                                class="scaled-image"
                                            /> -->
                                    </div>
                                    
                                    <div style="display: grid; grid-gap: 10px"
                                        v-if="(
                                            (this.forceSingleSig === undefined ? true : (this.forceSingleSig ? false : true)) &&
                                            (displayReason ? (reason ? true : false) : true) && 
                                            !success2 && 
                                            !loading2 && (
                                                    endpoint =='/drug-safe-retrieval' || 
                                                    endpoint =='/stock-take-submit' || (
                                                        (this.dualForMeds || this.dual) && 
                                                        ($store.state.user.role.permissions.includes('signature_primary') || $store.state.user.role.permissions.includes('signature_witness')) 
                                                    )
                                                ))">
                                        <h4 style="margin: 0; padding-left: 15px;text-align: left">Signature 2 {{ optional == true ?
                                                '(optional)' : ''
                                        }}</h4> 
                                        <input
                                            v-on:keyup.enter="verifySig(2)"
                                            ref="sig2username"
                                            type="text"
                                            v-model="sig2.name"
                                            placeholder="Username..."
                                        >
                                        <input
                                            v-on:keyup.enter="verifySig(2)"
                                            ref="sig2password"
                                            type="password"
                                            v-model="sig2.password"
                                            :placeholder="success1 ? 'Signed' : 'PIN/Password...'"
                                            autocomplete="one-time-code"
                                        >
                                        <div class="green-button"
                                            @click="verifySig(2)">
                                            Sign
                                        </div>
                                    </div>
                                    <div style="display: grid; grid-gap: 10px; margin-left: 3em; align-content: flex-start"
                                        v-if="(displayReason ? (reason ? true : false) : true) && witness">
                                        <h4 style="margin: 0; padding-left: 10px;">Witness</h4>
                                        <input ref="witnessname" type="text" v-model="witnessName" placeholder="Witness Name">
                                        <input ref="witnessrole" type="text" v-model="witnessRole" placeholder="Witness Role">
                                        <input ref="witnessref" type="text" v-model="witnessRefNumber" placeholder="Witness Ref Number">
                                    </div>
                                    <div v-if="!success2 && loading2">
                                        <i class="pi pi-spin pi-spinner"
                                            style="font-size: 2rem !important;margin-top: 2em;"></i>
                                    </div>
                                    <div v-if="success2"
                                        style="margin-left: 3em; display: flex; align-items: center; margin-top: 2em;">
                                        <h4
                                            style="color: green;margin: 0; border: 2px solid green; padding: 5px 10px; border-radius: 5px">
                                            Signature 2 signed.</h4>
                                    </div>


                                </div>
                            </div>
                            <div v-else class="form-section-container" style="grid-template-columns: 1fr; margin: 0 20px;">
                                <p style="font-size: 1em; margin: 0;" v-if="!errMessage">Click complete to submit.</p>
                            </div>
                        </form>
                    </slot>
                </div>
                
                <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 5px 20px; padding-top: 0px" v-if="witness || noAuthRequired || ((!(this.dualForMeds || this.dual) || $store.state.user.role.permissions.includes('single_signature_primary')) && success1) || ((optional || success2) && success1)">
                    <slot v-if="!witness" name="footer" style="display: flex;"> 
                 <!-- || singleSig -->
                         <div v-if="noAuthRequired || ((!(this.dualForMeds || this.dual) || $store.state.user.role.permissions.includes('single_signature_primary')) && success1) || ((optional || success2) && success1)"
                            style="margin: 0 auto; text-align: center;display: flex; align-items: center; margin-bottom: 2em; margin-top: 2em; cursor: pointer"
                            @click=" noAuthRequired ? noAuthSignature() : submitAction()">
                            <h3 style="margin: 0; border-bottom: 2px solid black; width: fit-content;">{{errMessage ? 'Retry' : 'Complete'}}</h3>
                        </div>
                    </slot>
                    <slot v-else name="footer" style="display: flex;">
                        <div v-if="success1 && witnessRole && witnessName"
                            style="margin: 0 auto; text-align: center;display: flex; align-items: center; margin-bottom: 2em; margin-top: 2em; cursor: pointer"
                            @click="submitAction">
                            <h3 style="margin: 0; border-bottom: 2px solid black; width: fit-content;">{{errMessage ? 'Retry' : 'Complete'}}</h3>
                        </div>
                    </slot>
                </div>
            </div>
            
            <div v-else  class="modal-container" style="min-width: 500px; width: 500px; margin: 0 auto; display: grid;">
                <p style="font-size: 1em;font-weight: 500; text-align: center">Submitting request...</p>
                <i class="pi pi-spin pi-spinner"
                    style="font-size: 2rem !important;padding-top: 1em; padding-bottom: 1em; color: green; margin: 0 auto"></i>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import FirebaseService from "../../services/FirebaseService";
import {toRaw} from 'vue'

export default {
    props: ['single', 'loop', 'close', 'endpoint', 'endpoint2', 'isSuccessful', 'payload', 'payload2', 'header', 'manual_loop', 'stock_items', 'discardAvailable',
        'dual', 'warning', 'prescriber', 'notes', 'witness', 'optional', 'drugReg', 'saveContext', 'displaySaveContext', 'noAuthRequired', 'bulkAdminister', 'displayReason','isStatus', 'forceSingleSig'],
    components: {
    },
    computed: {
        getNotes() {
            if (this.payload.notes) return this.payload.notes;
            return this.verification.notes;
        },
        dualForMeds() {
            if (this.payload.has_scheduled_items) return this.payload.has_scheduled_items;
            // payload can be drugs (everything), or stock_items (adjustmentmodal), or, ?? (newstocktake)
            let payloadDrugs = ""
            let found = false
            if (this.payload.drugs != null) {
                payloadDrugs = toRaw(this.payload.drugs)
            } 
            else if (this.payload.stocktake != null) {
                payloadDrugs = toRaw(this.payload.stocktake)
            }
            else if (this.payload.stock_items != null) {
                payloadDrugs = toRaw(this.payload.stock_items)
            }
            console.log("payloadDrugs")
            console.log(payloadDrugs)


            if (payloadDrugs != "") {
                let drugTagsInRequest = []
                payloadDrugs.forEach((drug) => {
                    console.log(drug.tags)
                    drugTagsInRequest =  drugTagsInRequest.concat(drug.tags)
                });
                const currentLocationDuelSigDrugTags = toRaw((this.$store.getters.getCurrentLocation).facility?.double_signature_drugs)
                console.log("currentLocationDuelSigDrugTags")
                console.log(currentLocationDuelSigDrugTags)
                found = drugTagsInRequest.some(v => currentLocationDuelSigDrugTags.includes(v))
            }
            return found
        }
    },
    data() {
        return {
            selected_stock_item: null,
            submitLoading: false,
            signError: 'You cannot use the same sign-in for both signatures.',
            errMessage: '',
            errMessage2: '',
            witnessName: '',
            witnessRole: '',
            witnessRefNumber: '',
            toSubmit: this.payload,
            toSubmit2: this.payload2,
            verification: {
                notes: '',
            },
            discardRemaining: false,
            singleSig: false,
            sigToken1: '',
            signature_1: '',
            sigToken2: '',
            signature_2: '',
            loading1: false,
            loading2: false,
            sig1: {
                name: '',
                password: '',
                company_id: this.$store.state.company.uuid,
            },
            success1: false,
            sig2: {
                name: '',
                password: '',
                company_id: this.$store.state.company.uuid,
            },
            success2: false,
            savedToBase: false,
            user_id: '',
            payloads:null,
            payloadsIndex: 0,
            reason:'',
        }
    },
    methods: {
        noAuthSignature(){
            axios.post('/account-signature', {}, this.$store.state.header).then(res=>{
                console.log('this is the account sig res', res.data);
                this.signature_1 = res.data.uuid;
                this.success1 = true;
                this.submitAction();
            }).catch(err=>{
                console.log('this is the error for account sig request', err.response.data);
            })
        },
        saveFormToFirebase() {
            this.user_id = JSON.parse(localStorage.getItem('user_id'));
            console.log('this is the user id', this.user_id);
            let fireload = {
                payload: { ...this.payload, endpoint: this.endpoint },
                saveContext: this.saveContext ? this.saveContext : '',
                header: { ...this.$store.state.header },
                userId: this.user_id,
            };
            FirebaseService.getForm(this.user_id).then((res)=>{
                console.log('USING GET FORM FIREBASE FUNCTION', res);
                // if user document does not exist yet, just create a new one.
                if(res.data() == undefined){
                    console.log('NO FORMS SAVED BY THIS USER YET', res.data());
                    FirebaseService.create(fireload, this.user_id).then(() => {
                        console.log("Created new item successfully!");
                        this.savedToBase = true;
                        localStorage.setItem('savedform',  JSON.stringify(fireload));
                    }).catch(e => {
                        console.log(e);
                    });
                } else{
                    // if user document does exist, delete previous one then create new one
                    console.log('A FORM HAS BEEN SAVED BY THIS USER ALREADY', res.data());
                    FirebaseService.delete(this.user_id).then(() => {
                        console.log('existing form has been deleted');
                        FirebaseService.create(fireload, this.user_id).then(() => {
                            console.log('form has been replaced successfully');
                            localStorage.setItem('savedform', JSON.stringify(fireload));
                        })
                    })
                }
            })
        },
        closeModal() {
            console.log('hitting this closemodal function signaturemodal')
            this.$emit('close', true);
        },
        submitAction() {
            this.submitLoading = true;
            if ( (this.$store.state.user.role.permissions.includes('signature_primary') || this.$store.state.user.role.permissions.includes('signature_witness') == false ||  this.$store.state.user.role.permissions.includes('single_signature_primary')) && this.signature_1.length > 0){
                this.verification.signature_1 = this.signature_1;
            }
            if ( (this.dualForMeds || this.dual)  && (this.$store.state.user.role.permissions.includes('signature_primary') || this.$store.state.user.role.permissions.includes('signature_witness')) && this.signature_2.length > 0) {
                console.log('this is a dual sig');
                if (this.optional) {
                    if (this.signature_2) {
                        this.verification.signature_2 = this.signature_2;
                        console.log('this is an optional sig2 signature signed');
                    }
                    console.log('this is an optional sig');
                } else {
                    this.verification.signature_2 = this.signature_2;
                    console.log('this is a dual sig that is not optional');
                }
                console.log('running through dual conditional', this.verification)
            }
            // FOR REQUESTS LIKE LOOPING THROUGH MULTIPLE S8 MEDS TO REMOVE FROM REGISTER
            if (this.loop) {
                
                console.log('LOOPING THROUGH THE PAYLOAD DATA', this.loop, this.payload, typeof this.payload);
                let notes = this.verification.notes;
                delete this.verification.notes;
                this.payload.forEach((item, index) => {
                    setTimeout(()=>{
                        console.log('this is a note', notes);
                        // item.notes = notes;
                        item.verification = this.verification;
                        this.bulkItemsCompleted = 0;
                        axios.post(this.endpoint, item, this.$store.state.header).then(res => {
                            this.bulkItemsCompleted+=1;

                            console.log('looping through items', res);
                            this.$store.dispatch('getRefreshToken');
                            this.$emit('responseData', res.data);
                            setTimeout(() => {
                                if(this.endpoint == '/drug-safe-retrieval'){
                                    this.$emit('drugSafeRetrievalId', res.data);
                                } else{
                                    console.log('THIS IS THE SETTIMEOUT issuccessful', res.data, index);
                                    this.$emit('isSuccessful', this.endpoint == '/administer' ?  {data: item.calendar_item_id, response: res.data, index: index} : res.data);
                                }
                                if(index == (this.payload.length -1)){
                                    console.log('hitting the close modal from the signature modal', index, this.payload.length);
                                    this.closeModal();
                                }
                            }, 200);
                            this.errMessage = '';

                        }).catch(err => {
                            console.log('this is the error 290', err, err.response, err.response.data, err.response.data.errors);
                            this.errMessage = err.response.data.message +' '+ Object.values(err.response.data.errors).flat()[0];
                            this.submitLoading = false;
                            this.sig1 = {
                                name: '',
                                password: '',
                                company_id: this.$store.state.company.uuid,
                            };
                            this.sig2 = {
                                name: '',
                                password: '',
                                company_id: this.$store.state.company.uuid,
                            };
                            this.loading1 = false;
                            this.success1 = false;
                            this.success2 = false;

                            this.singleSig = false;
                            if (this.saveContext) {
                                this.saveFormToFirebase();
                            }
                            return ;
                        });
                    },100)
                   
                })
            } else if(this.manual_loop){
                console.log('this is a manual loop process', this.payload);
                this.toSubmit = {...this.payloads[this.payloadsIndex]};
                console.log('hitting at 375');
                if (!this.verification.notes) {
                    delete this.verification.notes;
                } else {
                    delete this.verification.notes;
                }
                

                this.toSubmit.verification = this.verification;
                if (this.toSubmit2) {
                    this.toSubmit2.verification = this.verification;
                }
                if(this.selected_stock_item){
                    this.toSubmit.stock_item_id = this.selected_stock_item.uuid;
                }
                // if(this.witness){
                //     this.toSubmit.witness_role = this.witnessRole;
                //     this.toSubmit.witness_name = this.witnessName;
                // }
                // this.$store.dispatch('getRefreshToken');
                this.toSubmit.notes = this.toSubmit.notes+` \n Reason: ${this.reason}`;

                if(((this.drugReg && this.drugReg.length) || this.endpoint == '/drug-safe-retrieval') && !this.discardRemaining){ 
                    this.toSubmit.discard_amount = '0';
                }
                console.log('this is the toSubmit', this.toSubmit);
                axios.post(`${this.drugReg && this.drugReg.length ? '/drug-safe-retrieval' : this.endpoint}`, this.toSubmit, this.header ? this.header : this.$store.state.header).then(res => {
                    
                    console.log('action has been submitted', res);
                    this.$store.dispatch('getRefreshToken');
                    this.$emit('responseData', res.data);
                    

                    this.submitLoading = false;
                    this.sig1 = {
                        name: this.sig1.name ? this.sig1.name : '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.sig2 = {
                        name:  this.sig2.name ? this.sig2.name : '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.loading1 = false;
                    this.success1 = false;
                    this.success2 = false;


                    setTimeout(() => {
                        
                        if(this.payloadsIndex+1 == this.payloads.length){
                            this.$emit('isSuccessful',  this.endpoint == '/administer' ?  {data: this.toSubmit.calendar_item_id, response: res.data, index: null} : res.data);
                            console.log('hitting the close modal on 494');
                            this.closeModal();
                        } else{
                            this.payloadsIndex +=1
                        }
                        
                    }, 200);
                    this.errMessage = '';

                }).catch(err => {
                    console.log('this is the error updated 389', err.response.data.message, err.response.data.errors);
                    // this.errMessage = err.response.data.errors;
                    // this.errMessage.concat('\n '+err.response.data.errors)
                    
                    if(err.response.data && err.response.data.message && err.response.data.message.includes('ePrescribing service could not be contacted')){
                        this.errMessage = 'Unfortunately the prescription has not been submitted as the request has timed out. Feel free to try again or issue a paper prescription.';
                        this.errMessage2 = err.response.data.errors ? err.response.data.errors : ''
                    } else{
                        this.errMessage = err.response.data;
                        this.errMessage2 = err.response.data.errors;
                    }
                    
                    this.submitLoading = false;
                    this.sig1 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.sig2 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.loading1 = false;
                    this.success1 = false;
                    this.success2 = false;

                    this.singleSig = false;
                    if (this.saveContext) {
                        this.saveFormToFirebase();
                    }
                });
            }  else {
                this.toSubmit = {...this.payload};
                console.log('hitting at 465');
                if (!this.verification.notes) {
                    delete this.verification.notes;
                } else {
                    this.toSubmit.notes = this.verification.notes;
                    delete this.verification.notes;
                }
                if (this.signature_1 &&  this.signature_1.length > 0) {
                    this.verification.signature_1 = this.signature_1
                }
                if (this.signature_2 &&  this.signature_2.length > 0) {
                    this.verification.signature_2 = this.signature_2
                }
                console.log('this is the toSubmit', this.toSubmit);

                if (this.endpoint2 !== '/warehouse/sign-off-invoices' && this.endpoint2 !== '/warehouse/sign-off-orders'){
                    this.toSubmit.verification = this.verification;
                }
                if (this.toSubmit2) {
                    this.toSubmit2.verification = this.verification;
                }
                if(this.selected_stock_item){
                    this.toSubmit.stock_item_id = this.selected_stock_item.uuid;
                }
                // if(this.witness){
                //     this.toSubmit.witness_role = this.witnessRole;
                //     this.toSubmit.witness_name = this.witnessName;
                // }
                // this.$store.dispatch('getRefreshToken');
                if (this.toSubmit.notes) {
                    this.toSubmit.notes = this.toSubmit.notes+`${this.reason && !this.endpoint.includes('effectiveness') ? ' \n Reason:'+this.reason : ''}`;
                }
                console.log('this is the toSubmit post concat', this.toSubmit.notes);

                if(((this.drugReg && this.drugReg.length) || this.endpoint == '/drug-safe-retrieval') && !this.discardRemaining){ 
                    this.toSubmit.discard_amount = '0';
                }
                
                axios.post(`${this.drugReg && this.drugReg.length ? '/drug-safe-retrieval' : this.endpoint}`, this.toSubmit, this.header ? this.header : this.$store.state.header).then(async res => {
                    let endpoint2Response = null;  
                    if (this.endpoint2) {
                        if (this.endpoint2 == '/warehouse/sign-off-invoices' || this.endpoint2 == '/warehouse/sign-off-orders') {
                            // this is the payload for a direct sign off on created invoice/order
                            this.toSubmit2.invoice_id = [ res?.data?.invoice_id ],
                            this.toSubmit2.location_id = this.toSubmit.location_uuid ||  this.payload2.location_id
                            this.toSubmit2.notes = this.toSubmit.notes;
                        } else {
                            this.toSubmit2.notes = this.toSubmit2.notes + `\nReference: ${this.endpoint == '/stock-take-submit' ? res.data.stocktake_items[0].uuid : res.data}`;
                        }
                        
                           await axios.post(`${this.endpoint2}`, this.toSubmit2, this.$store.state.header).then(res1 => {
                                console.log('the second endpoint has been submitted', res1);
                                endpoint2Response = res1.data;
                                if (this.$route.name == 'Drug Register') {
                                    console.log('loading the drug register stuff');
                                    this.$store.dispatch('getDrugRegister', {
                                        sort_direction: 'desc',
                                        col: 'created_at',
                                        page: 1,
                                        filters: {
                                            transaction_id: '',
                                            to_from: '',
                                            entered_by: '',
                                            date: {
                                                start: '',
                                                end: ''
                                            },
                                            drug: '',
                                            imprest_only: false
                                        }
                                    });
                                } else if (this.$route.name == 'Invoices') {
                                    this.$store.dispatch('getPurchaseInvoices', {
                                        sort_direction: 'desc',
                                        col: 'created_at',
                                        facility_id: this.$store.state.currentLocation.uuid,
                                        page: 1,
                                        filters: {
                                            status: [],
                                            party: '',
                                            drug: '',
                                            date: {
                                                start: '',
                                                end: ''
                                            },
                                            invoice_number: '',
                                            show_active: true,
                                        },

                                    })
                                } else if (this.$route.name == 'Orders') {
                                    this.$store.dispatch('getOrders', {
                                        sort_direction: 'desc',
                                        facility_id: this.$store.state.currentLocation.uuid,
                                        col: 'created_at',
                                        page: 1,
                                        filters: {
                                            status: [],
                                            party: '',
                                            drug: '',
                                            date: {
                                                start: '',
                                                end: ''
                                            },
                                            invoice_number: '',
                                            show_active: true,
                                        },

                                    });
                                }
                            }).catch(err => {
                                console.log('this is the error updated', err, err.response, err.response.data, err.response.data.errors);
                                this.errMessage = err.response.data.errors
                                this.submitLoading = false;
                                this.sig1 = {
                                    name: '',
                                    password: '',
                                    company_id: this.$store.state.company.uuid,
                                };
                                this.sig2 = {
                                    name: '',
                                    password: '',
                                    company_id: this.$store.state.company.uuid,
                                };
                                this.loading1 = false;
                                this.success1 = false;
                                this.success2 = false;

                                this.singleSig = false;
                                if (this.saveContext) {
                                    this.saveFormToFirebase();
                                }
                            });
                        
                    }
                    console.log('action has been submitted', res);
                    this.$store.dispatch('getRefreshToken');
                    this.$emit('responseData', res.data);
                    setTimeout(() => {
                        
                        if(this.endpoint == '/drug-safe-retrieval'){
                            this.$emit('drugSafeRetrievalId', res.data);
                        } else{
                            if(this.bulkAdminister){
                                console.log('bulk administer in signature modal path', this.toSubmit);
                                /**
                                 * Add response data to submitted item so it
                                 * can be accessed by SelectedPatientAdministration's submitIsCompleted method for
                                 * redo logic
                                 */
                                this.toSubmit.bulk_meds.forEach((item, index) => {
                                    item.response = res.data[index];
                                });
                                this.$emit('isSuccessful', this.toSubmit);
                            } else{
                                this.$emit('isSuccessful',  this.endpoint == '/administer' ?  {data: this.toSubmit.calendar_item_id, response: res.data, index: null} : (this.endpoint2 == '/warehouse/sign-off-invoices' || this.endpoint2 == '/warehouse/sign-off-orders') ? endpoint2Response : res.data, this.toSubmit);
                            }
                            
                        }
                        console.log('hitting the closemodal at 642');
                        if(this.endpoint2 == '/administer-expired-chart-note'){
                            this.$emit('isSuccessful', true);
                        } else{
                            this.closeModal();
                        }
                        
                    }, 200);
                    this.errMessage = '';

                }).catch(err => {
                    console.log('this is the error updated 389', err.response.data.message, err.response.data.errors);
                    // this.errMessage = err.response.data.errors;
                    // this.errMessage.concat('\n '+err.response.data.errors)
                    
                    if(err.response.data && err.response.data.message && err.response.data.message.includes('ePrescribing service could not be contacted')){
                        this.errMessage = 'Unfortunately the prescription has not been submitted as the request has timed out. Feel free to try again or issue a paper prescription.';
                        this.errMessage2 = err.response.data.errors ? err.response.data.errors : ''
                    } else{
                        this.errMessage = err.response.data;
                        this.errMessage2 = err.response.data.errors;
                    }
                    
                    this.submitLoading = false;
                    this.sig1 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.sig2 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.loading1 = false;
                    this.success1 = false;
                    this.success2 = false;

                    this.singleSig = false;
                    if (this.saveContext) {
                        this.saveFormToFirebase();
                    }
                });
            }
        },
        replaceStockItemId(){
            
        },
        endpointConditon(){
          return this.endpoint == '/drug-safe-retrieval' || this.endpoint == '/stock-take-submit' || this.endpoint == '/get-stock-item' ||  this.endpoint == '/warehouse/sign-off-invoices' || this.endpoint == '/warehouse/sign-off-orders' || this.endpoint == '/adjustments'|| this.endpoint == `/location/${this.$store.state.currentLocation.uuid}/outgoing` || this.endpoint == '/transfers/new-transfer' || this.endpoint == '/single-transfer/reverse' || this.endpoint == '/single-transfer/approve' || this.isStatus === 'Destruction' || this.endpoint == '/single-drug-entry/reverse' || this.endpoint == '/single-delivery/action' || this.endpoint==='/warehouse/delete-batch' || this.endpoint=='/warehouse/reverse-invoice-order' || this.endpoint==='/warehouse/cancel-invoice-order' || this.endpoint == '/new-delivery'
        },
        verifySig(signatureEntry) {
            if (signatureEntry == 1 && (this.sig2.name != this.sig1.name) && !this.singleSig) {
                this.loading1 = true;
                axios.post('/sign', this.sig1, this.$store.state.header).then(res => {
                    console.log('this is the signature token', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.sigToken1 = res.data.access_token;
                    return res;
                }).then(() => {
                    axios.post('/signature', { signature_token: this.sigToken1, type: '' }, this.$store.state.header).then(res => {
                        console.log('this is the signature id', res.data);
                        this.$store.dispatch('getRefreshToken');
                        this.signature_1 = res.data.uuid;
                        this.success1 = true;
                        this.loading1 = false;
                        if((this.dualForMeds || this.dual)){
                            if(this.success2 && this.success1){
                                this.submitAction()
                            }
                        } else{
                            if(this.success1){
                                this.submitAction()
                            }
                        }
                        
                        // if(res1.data.single_signature_primary && !this.drugReg && this.endpoint != "/stock-take-submit" && this.endpoint != "/new-delivery" && (!this.payload.pending)){
                        //     console.log('this is a single sig primary')
                        //     this.singleSig = true;
                        // }
                    });
                }).catch((err) => {
                    // this.errMessage = 'Incorrect username/password';
                    console.log('this is the sig error', err);
                    this.errMessage =  err.response.data;
                    this.errMessage2 = '';
                    this.submitLoading = false;
                    this.sig1 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.loading1 = false;
                    this.success1 = false;
                    this.success2 = false;
                    this.singleSig = false;
                    if (this.saveContext) {
                        this.saveFormToFirebase();
                    }
                });

            }
            if (signatureEntry == 2 && (this.sig2.name != this.sig1.name) && !this.singleSig) {
                this.loading2 = true;
                this.singleSig = false;
                axios.post('/sign', this.sig2, this.$store.state.header).then(res => {
                    console.log('this is the signature token', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.sigToken2 = res.data.access_token;
                    // if(res.data.single_signature_primary){
                    //     console.log('this is a single sig primary')
                    //     this.singleSig = true;
                    // } 
                    return res;
                }).then(() => {
                    axios.post('/signature', { signature_token: this.sigToken2, type: 'Witness' }, this.$store.state.header).then(res => {
                        // console.log('this is the signature id for sig 2', res.data);
                        this.$store.dispatch('getRefreshToken');
                        this.signature_2 = res.data.uuid;
                        console.log('this is the signature id for sig 2', this.signature_2);
                        this.loading2 = false;
                        this.success2 = true;
                        if((this.dualForMeds || this.dual)){
                            if(this.success2 && this.success1){
                                this.submitAction()
                            }
                        } else{
                            if(this.success1){
                                this.submitAction()
                            }
                        }
                        // this.errMessage = '';
                        // if(res1.data.single_signature_primary){
                        //         console.log('this is a single sig primary')
                        //         this.singleSig = true;
                        //     }
                    });
                }).catch((err) => {
                    // this.errMessage = 'Incorrect username/password';
                    this.errMessage = err.response.data;
                    this.errMessage2 = '';
                    this.submitLoading = false;
                    this.sig2 = {
                        name: '',
                        password: '',
                        company_id: this.$store.state.company.uuid,
                    };
                    this.loading2 = false;
                    this.success2 = false;
                    // this.singleSig = false;
                    if (this.saveContext) {
                        this.saveFormToFirebase();
                    }
                });
            }
            if (this.witness) {
                let witnessLoad = {
                    name: this.witnessName,
                    reference_number: this.witnessRefNumber,
                    role: this.witnessRole,
                    type: 'Witness'
                }

                axios.post('/plain-signature', witnessLoad, this.$store.state.header).then(res => {
                    console.log('this is the signature id', res.data);
                    this.$store.dispatch('getRefreshToken');
                    this.signature_2 = res.data.uuid;
                }).catch((err) => {
                    // this.errMessage = 'Incorrect username/password';
                    this.errMessage = err.response.data.errors;
                    this.loading1 = false;
                    this.success1 = false;
                    this.success2 = false;
                    this.singleSig = false;
                });
            }
        },
        focusInput() {
            // Focus the password if username prefilled
            if (this.sig1.name && (this.displayReason ? (this.reason ? true : false) : true) ) {
                this.$refs?.sig1password?.focus();
            }
            // Else focus the username
            else if (this.displayReason ? (this.reason ? true : false) : true){
                this.$refs.sig1username.focus();
            }
        },
        authforsignoff() {
            return this.noAuthRequired || (((this.dualForMeds || this.dual) || this.$store.state.user.role.permissions.includes('single_signature_primary')) && (this.success1 || this.success2)) || ((this.optional || this.success2) && this.success1);
        },
        finalSignoffInvoices() {
            if (this.authforsignoff()) {
                this.submitAction()
            }
        },
        onesignature() {
            if (this.$store.state.user.role.permissions.includes('signature_primary')) {
                return true
            } else {
                if(this.$store.state.user.role.permissions.includes('signature_witness') === true){
                    return false
                }else if(this.$store.state.user.role.permissions.includes('single_signature_primary')){
                    return true
                }
            }
        }
    },
    mounted() {
                if(this.noAuthRequired){
            this.noAuthSignature();
        }
        if(this.stock_items){
            this.selected_stock_item = this.stock_items[0];
        }
        console.log('this user has a username cached', localStorage.getItem('username'),this.payload);
        if(localStorage.getItem('username')){
            console.log('does have username cached');
            this.sig1.name = localStorage.getItem('username');
        }
        if(!this.noAuthRequired){
            this.focusInput();
        }
        if(this.manual_loop ){
            let payloads = [];
            if(this.endpoint != '/adjustments'){
                this.payload.drugs.forEach(drug=>{
                    let pay = {...this.payload};
                    pay.drugs = [drug];
                    // let pay = {
                    //     type: this.payload.type,
                    //     to_from: this.payload.to_from,
                    //     drugs: [drug],
                    //     notes: this.payload.notes,
                    //     prescriber_uuid: this.payload.prescriber_uuid,
                    //     patient_uuid: this.payload.patient_uuid
                    // }
                    payloads.push(pay);
                })
            } else{
                console.log('this is the adjustments endpoint')
                this.payload.stock_items.forEach(stock=>{
                    let pay = {...this.payload};
                    pay.stock_items = [stock];
                    payloads.push(pay);
                })
            }
            
            console.log('this is the manual payload restruct', payloads);
            this.payloads  = payloads;
        }
        
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

 

 

  

.right-form-section-drug-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    input {
        background-color: $babyblue;
    }
}

 

.drug-entry-transfer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    border-collapse: collapse;

    p {
        margin: 0;
        text-align: left;
        padding: 0 15px;
    }

    background-color: $grey;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-bottom: 5px solid white;
    border-radius: 4px;
}


.blue-button {
    background-color: $strongblue !important;
    padding: 5px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    color: white;
}

.right-form-section-panel {
    padding: 0 20px;
}

.full-width-verify.verification-form {
    grid-template-columns: 1fr 1fr;

    div {
        width: 100%;

        input {
            width: 100% !important;
        }
    }
}

.selected-location {
    background-color: $complete;
    color: white;
}

.selected-hollow-button {
    background-color: $strongblue;
    color: white;
}

.discard-checkbox{
    height: 1.5em;
    margin: 0;
    padding: 0;
}
.signborder {
    background: none !important;
    border: 1px solid #f3f4f4;
}
</style>