<template>
  <div class="drug-register-view view">
    <div class="actions-panel panel" style="width: fit-content; display: flex; flex-direction: column;">
      <h3 class="panel-header">Actions</h3>
      <!-- if using carton cloud integration and have a can_do_admin_roles, will enable -->
      <button :disabled="cartonCloudRestriction" @click="displayDeliveryModal = true" class="actions-menu-item">New Delivery</button>
    </div>
    <div class="panel" v-if="$store.state.user.role.permissions.includes('can_view_deliveries')">
      <h3 class="panel-header">{{ getDynamicName() }}</h3>
      <div class="drug-register-table table-container" style="width: 86.5vw;display: flex;flex-direction: column;">
        <div class="filters" style="display: flex; flex-wrap: wrap; align-items: end; height: max-content; justify-content: space-between;margin-bottom: 20px;">
          <div style="display: flex; align-items: center;flex-wrap: wrap;gap: 12px;">
          <!-- SEARCH SECTION -->
          <!-- <div style="display: flex; align-items: end;"> -->

            <!-- <div class="date-search-container search-container calenderdatelabel">
              <label for="calenderDate">Date</label>
              <Calendar v-model="date" id="calenderDate" selectionMode="range" placeholder="Select a Date..." dateFormat="dd/mm/yy" />
            </div> -->
            <div class="date-search-container search-container calenderdatelabel">
                <div class="calendar-input-container" id="calenderDate">
                  <Calendar v-model="date" selectionMode="range" placeholder="Date..." dateFormat="dd/mm/yy" />
                 </div>
            </div>


            <!-- <div class="text-search-container search-container">
             <input v-model="form.filters.drug" placeholder="Drug..." style="position: relative" />
            </div> -->

            <!-- <div class="text-search-container search-container">
             <input v-model="form.filters.party" placeholder="Supplier..." style="position: relative" />
            </div> -->

            <div class="text-search-container search-container calenderdatelabel">
              <div class="calendar-input-container">
             <input id="medication" v-model="form.filters.drug" @input="debouncedLoadEntries" placeholder="Drug..." style="position: relative" />
            </div>
            </div>

            <div class="text-search-container search-container calenderdatelabel">
              <div class="calendar-input-container">
             <input v-model="form.filters.party" @input="debouncedLoadEntries" id="party" placeholder="Supplier..." style="position: relative" />
            </div>
            </div>

            <!-- Invoice Number Filter -->
            <div class="text-search-container search-container calenderdatelabel">
              <div class="calendar-input-container">
               <input v-model="form.filters.invoice_number" @input="debouncedLoadEntries" id="inovoiceNumber" placeholder="Invoice Number..." style="position: relative" />
              </div>
            </div>

            <button class="button submit-button" @click="loadEntries()">Search</button>
            <button class="button clear-button" @click="clearEntriesFilters()">Clear</button>
     
          <!-- Sync -->
          <!-- <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft')" class="button submit-button" style="background-color: #b5a248;" @click="syncEntries()">Sync</button> -->

          <!-- <div style="display: flex; gap: 20px; align-items: center"> -->
            <button :class="ordersToSign > 0 ? 'red-button' : 'blue-button'" @click="toggleSelectAll(ordersToSign > 0 ? false : true)">{{ ordersToSign > 0 ? 'Unselect All' : 'Select All'}}</button>
            <button  class="blue-button" :class="ordersToSign > 0 ? 'blue-button' : 'grey-button'" @click="signOrders()">Sign</button>
            <!-- MOVE TO OTHER LOCATION -->
            <!-- <button class="blue-button" v-if="ordersToSign > 0"  @click="moveToOtherLocation()">Set New Location</button> -->
            
            <div class="calenderdatelabel" style="display: flex;flex-direction: row;align-items: center;gap: 10px">
              <label for="attentionRequired" style="margin: 0px;">Attention Required</label>
              <InputSwitch id="attentionRequired" v-model="checked" @change="updateListing"/>
            </div>
            
            <!-- SELECT FILTER SECTION -->
               <MultiSelect class="" id="multistatusselect" v-model="selectedStatuses" :options="statusSelect" @change="updateStatusList" optionLabel="status" placeholder="Select Status..." />

           <div style="display: flex; align-items: center">
            <div class="select-filters-container">

              <p @click="() => { form.filters.show_active = true; loadEntries() }"
                v-bind:class="[form.filters.show_active == true ? 'active-select-filter select-filter-item' : '']"
                class="select-filter-item">Active Delivery</p>

              <p @click="() => { form.filters.show_active = false; loadEntries() }"
                v-bind:class="[form.filters.show_active == false ? 'active-select-filter select-filter-item' : '']"
                class="select-filter-item">Cancelled Delivery</p>

            </div>
            <!-- Sync -->
            <div style="margin-left: 10px; max-width: 195px" class="date-search-container search-container calenderdatelabel">
                <div class="calendar-input-container" id="syncFromDatePicker" :style="{border: $store.state.syncStatus?.invoices?.noData ? '1px solid red' : 'none'}" >
                  <Calendar v-model="syncFrom" placeholder="Sync from date..." dateFormat="dd/mm/yy" selectionMode="range"/>
                 </div>
            </div>
            <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && !$store.state.syncStatus?.invoices?.syncUUIDSucces" class="button submit-button" style="background-color: #b5a248 ;margin-left: 10px; min-width: 56px" @click="$store.dispatch('syncEntries', {endpoint: '/integration/sync-invoices', type: 'import_invoices',value: true, sync: 'invoices', syncFrom})" :disabled="$store.state.syncStatus?.invoices?.syncLoader || syncButtonDisable">
              <i v-if="$store.state.syncStatus?.invoices?.syncLoader" style="font-size: 1.3rem !important;margin: auto;padding: 0px;" class="pi pi-spin pi-spinner" ></i>
              <span v-else>{{getCount ? 'Check Again' : 'Sync'}}</span>
            </button>
            <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && $store.state.syncStatus?.invoices?.syncUUIDSucces" class="button submit-button" style="background-color: #a0b302 ;margin-left: 10px" @click="refreshList()">
              Refresh
            </button>
            <button v-if="($store.state.user.integration_type === 'carton-cloud' || $store.state.user.integration_type === 'microsoft') && $store.state.syncStatus?.invoices?.syncError" class="button submit-button" style="background-color: #e04f39 ;margin-left: 10px; max-width: 56px; min-width: 56px" @click="displaySuccessModal = true">
              Error
            </button>
            <!-- Uncomment when sync function for updates is ready -->
            <!-- <p v-if="$store.state.loadingDeliveriesData" style="margin-left: 10px;">Syncing data from {{ $store.state.user.integration_type }}. Please wait...</p> -->
            <p style="margin-left: 10px;">Last synced: {{ getCount ? this.$store.state.syncStatus?.invoices?.syncLoader ? '--' : "The request is still in progress." : $store.getters.getInvoicesLastSynced }}</p>
          </div> 

            <!-- <div class="calenderdatelabel">
              <label for="allTypes">Stock Type</label>
              <button class="button types-button" id="allTypes">All Types</button>
            </div> -->

           
          <!-- </div> -->

          <!-- <div style="display: flex; align-items: center"> -->
            
              <!-- <div class="select-filters-container">

              <p @click="()=>{tags.s8 = !tags.s8; autoAll()}" 
                v-bind:class="[tags.s8 == true ? 'active-select-filter' : 'outline-button']" :style="tags.s8 ? { paddingTop: '8px'}:{paddingTop: '6px'}" style="height: 33px;">S8 Only</p>
                <button @click="()=>{tags.s8 = !tags.s8; autoAll()}" 
                v-bind:class="[tags.s8 == true ? 'active-select-filter s8-button' : 'outline-button s8-button']">S8 Only</button>
            </div> -->
          <!-- </div> -->
          
          
         
          </div>
          <!-- </div> -->
          
        </div>
        <div v-if="!$store.state.loadingDeliveriesData" style="display: flex; align-items: start; overflow-x: hidden">
          <div class="drug-register-table table-container" style="padding-top: 10em;" v-if='!$store.state.user.role.permissions.includes("can_view_deliveries")' >
            <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
          </div>
        <table v-else class="data-table" cellspacing="0">
          <tr class="table-headers" >
            <th style="width: 50px"></th>
            <th>
              <h3 @click="sortBy('delivery_date', 'asc')">Delivery Date</h3>
              <div v-if="form.col == 'delivery_date'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  delivery_date-sort" v-if="form.col =='delivery_date' && form.sort_direction =='asc'" @click="sortBy('delivery_date', 'desc')"></i>
              <i class="pi pi-sort delivery_date-sort" v-else @click="sortBy('delivery_date', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('supplier_name', 'asc')">Supplier</h3>
              <div v-if="form.col == 'supplier_name'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  supplier_name-sort" v-if="form.col =='supplier_name' && form.sort_direction =='asc'" @click="sortBy('supplier_name', 'desc')"></i>
              <i class="pi pi-sort supplier_name-sort" v-else @click="sortBy('supplier_name', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('invoice_number', 'asc')">Invoice Number</h3>
              <div v-if="form.col == 'invoice_number'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  invoice_number-sort" v-if="form.col =='invoice_number' && form.sort_direction =='asc'" @click="sortBy('invoice_number', 'desc')"></i>
              <i class="pi pi-sort invoice_number-sort" v-else @click="sortBy('invoice_number', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('completed_by', 'asc')">Completed By</h3>
              <div v-if="form.col == 'completed_by'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  completed_by-sort" v-if="form.col =='completed_by' && form.sort_direction =='asc'" @click="sortBy('completed_by', 'desc')"></i>
              <i class="pi pi-sort completed_by-sort" v-else @click="sortBy('completed_by', 'asc')"></i>
              </div>
            </th>
            <th>
              <h3 @click="sortBy('completed_date', 'asc')">Completed Date</h3>
              <div  style="display: inline; cursor: pointer">
              <i class="pi pi-sort  completed_date-sort" v-if="form.col =='completed_date' && form.sort_direction =='asc'" @click="sortBy('completed_date', 'desc')"></i>
              <i class="pi pi-sort completed_date-sort" v-else @click="sortBy('completed_date', 'asc')"></i>
              </div>
            </th>
            <th style="padding-left: 20px;">
              <h3 @click="sortBy('status', 'desc')">Status</h3>
              <div v-if="form.col == 'status'" style="display: inline; cursor: pointer">
              <i class="pi pi-sort  status-sort" v-if="form.col =='status' && form.sort_direction =='asc'" @click="sortBy('status', 'desc')"></i>
              <i class="pi pi-sort status-sort" v-else @click="sortBy('status', 'asc')"></i>
              </div>
            </th>
            <th style="width: 70px;"  v-if="form.filters.show_active">
              <h3>Select</h3>
            </th>
            
            

          </tr>
          <tbody class="table-data" v-for="(item, index) in getDrugEntries" :key="index">
            <tr @click="showContent(item, index)" :class="[ item.display && (item.display == true) ? 'open-transfer-row': '']">
                <td style="width: 20px">
                      <i v-if="item.display && (item.display == true)" class="pi pi-angle-down"></i>
                      <i v-else class="pi pi-angle-right"></i>
                </td>
                <td>{{item.delivery_date}}</td>
                <td>{{item.supplier_name || '-'}}</td>
                <td>
                  {{ item.invoice_number || '-'}}
                  <span v-if="item?.deprecated" style="margin-right: 5px;" class="s8-tag">Deprecated items</span>
                </td>
                <td>{{ (item.status == 'completed' && item.completed_by?.personal_information) ? item.completed_by?.personal_information?.firstname?.charAt(0).toUpperCase() + item.completed_by?.personal_information?.firstname?.slice(1) + ' ' +  item.completed_by?.personal_information?.lastname?.charAt(0).toUpperCase() + item.completed_by?.personal_information?.lastname?.slice(1) : '-' }}</td>
                <!-- <td>{{ (item?.completed_by === null || !item?.completed_by ? '-' : item.completed_by?.personal_information?.firstname?.charAt(0).toUpperCase() + item.completed_by?.personal_information?.firstname?.slice(1) + ' ' +  item.completed_by?.personal_information?.lastname?.charAt(0).toUpperCase() + item.completed_by?.personal_information?.lastname?.slice(1))}}</td> -->
                <td>{{item.completed_date || '-'}}</td>
                <td><p :class="[item.status == 'completed' ? 'correct-status-color' : item.status == 'cancelled' ? 'mismatch-status-color' :  (item.status == 'partial' || item.status == 'Partial' || item.status == 'reversed' ? 'mismatch-status-color ' : 'warning-status-color')]">{{ item.status ? item.status.slice(0,1).toUpperCase()+item.status.slice(1) :'' }}</p></td>
                <td v-if="form.filters.show_active">
                  <div @click.stop="() => { !item?.deprecated && selectItem(item, index) }" style="cursor: pointer" v-if="checkboxCondition(item)">
                    <div v-if="item.to_sign && (item.to_sign == true)" class="check-circle circle-on"></div>
                    <div v-else class="circle-off" :style="{borderColor: item?.deprecated && '#b8b8b8 !important', cursor: item?.deprecated && 'auto'}"></div>              
                  </div>
                  <!-- <div v-if="item.status == 'pending' || item.status == 'partial'" class="card flex justify-content-center">
                        <Checkbox v-model="item.to_sign" :binary="true" @click.stop="() => { selectItem(item, index) }" style="cursor: pointer;" />
                    </div> -->
                 </td>
                 
            </tr>
            <tr :ref="'expand-'+index" :id="'expand-'+index" class="expanded-content">
               <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr;">
              
                  <div class="expanded-transfer-container" style="grid-template-columns: 1fr 3fr; padding: 0;">
                   <div class="transfer-notes-container">
                     <div>
                       <h4 class="field-title">Reference Number</h4>
                       <div class="field-notes" style="width: 95%; height: fit-content;">
                           <p>{{item.reference_number || 'N/A'}}</p>
                       </div>
                     </div>
                     <div>
                       <h4 class="field-title">Notes</h4>
                       <div class="field-notes" style="width: 95%; height: 245px;">
                           <p>{{item.note}}</p>
                       </div>
                     </div>
                       
                   </div>

                     <div class="transfer-drugs-container">
                      <h4 class="field-title">Drugs</h4>
                       <div class="drug-field-tables" style="width: 100%;
                                                                border-radius: 4px;
                                                                height: 300px;">
                        <table style="padding: 0px 5px; border-bottom: 1px solid rgb(231, 233, 232);">
                            <tr>
                                <th>Name</th>
                                <th v-if="item?.deprecated" style="width: 130px;">Status</th>
                                <th style="width: 100px;">Count</th>
                                <!-- <th>Resident</th>
                                <th>Prescriber</th> -->
                            </tr>
                        </table>
                        <div style="height: 250px; overflow-y: scroll; background-color: white;">
                            <table style="padding: 0;">
                                <tr style="height: 25px;"  v-for="(value,key) in item.medications" :key="key">
                                    <td>{{value.medication}}</td>
                                    <td v-if="item?.deprecated" style="width: 130px;">
                                      <span v-if="value?.deprecated" class="s8-tag">Deprecated</span>
                                      <span v-else>
                                        --
                                      </span>
                                    </td>
                                    <td style="width: 100px;">{{value.count}}</td>
                                    <!-- <td>Patrick Souzasolopous</td>
                                    <td>Richard Kit</td> -->
                                </tr>
                            </table>
                        </div>
                       </div>

                     </div>
                       
                  </div>
               </div>
                <!-- TRANSFER ACTIONS -->
                <div class="transfer-actions-container">

                    <button  @click="editDelivery(item)" v-if="item.status === 'pending' && form.filters.show_active" class="transfer-action-button blue-button">Edit Delivery</button>

                    <button @click="cancelDelivery(item)"  v-if="item.status === 'pending' || item.status === 'partial' && form.filters.show_active" class="transfer-action-button error-button">Cancel Delivery</button>
                    <!-- <button class="button pending-button" style="min-width: 200px;">Reverse Delivery</button> -->
                    <button  @click="transferAction('Reverse', item)" v-if="(item.status === 'completed' ) && form.filters.show_active" class="transfer-action-button error-button">Reverse Delivery</button>
                    <button :disabled="item?.deprecated" :class="item?.deprecated && 'disabledButton'" v-if="item.status !== 'completed' && item.status !== 'reversed' && form.filters.show_active" class="transfer-action-button complete-button" @click="completedDeliveryDilog(item)">Complete Delivery</button>
                    <!-- <button  v-if="(item.status === 'completed' ) && form.filters.show_active" class="button warning-button" style="min-width: 200px;" >Reverse Delivery</button> -->

                    <!-- <button @click="signSingleOrder(item)" class="green-button" v-show="item.status == 'partial'">Sign Invoice</button> -->
                    <!-- <button @click="transferAction('Reject', item.invoice_id, item.expandedInfo.drugs)" class="transfer-action-button error-button" v-show="item.status == 'Pending'">Reject Delivery</button> -->
                    <!-- <button @click="transferAction('Reverse', item)" class="transfer-action-button error-button" v-show="item.status == 'completed'">Reverse Delivery</button> -->
                    <button @click="showContent(item, index)"  style="background-color: white; padding: 0px;"><i class="pi pi-times"></i></button>
                
                </div>
            </tr>    
          </tbody>
          
        </table>
        </div>
        <div v-else>
          <h3 v-if="$store.state.deliveryError">{{$store.state.deliveryError}}</h3>
          <i v-else class="pi pi-spin pi-spinner" style="font-size: 3rem !important;margin-top: 4em;"></i>
        </div>
          <div class="pagination-links" v-if="$store.state.drugEntries && $store.state?.drugEntries?.meta?.current_page">
            <p class="pagination-arrow" v-show="$store.state?.drugEntries?.meta?.current_page > 1" @click="displayPage(form.page - 1)"> &larr; </p>
            <p>Page {{$store.state?.drugEntries?.meta?.current_page}} of {{$store.state?.drugEntries?.meta?.last_page}}</p>
            <p class="pagination-arrow" v-show="$store.state?.drugEntries?.meta?.current_page < $store.state?.drugEntries?.meta?.last_page" @click="displayPage( $store.state?.drugEntries?.meta?.current_page + 1)"> &rarr; </p>
          </div>
       </div>
       <!-- <ActionModal :toAction="toAction" /> -->
       <SignatureModal  v-if="displayActionModal" @close="closeModal" @isSuccessful="isSuccessful"  :endpoint="endpoint" dual="true"
       :payload="payload"  :loop="true"/>

       <DeliveryModal transferType="Deliveries" v-if="displayDeliveryModal" @close="close" buttonName="New Deliveries"/>
    </div>
    <div v-else class="drug-register-table table-container" style="padding-top: 5em; height: 78vh; grid-template-rows: [row1-start] 70px [row1-end] minmax(200px, 64vh) [third-line] 50px [fourth-line];" >
        <h3 style="border: 2px solid black; border-bottom: 2px solid black; width: fit-content; height: fit-content; margin: 0 auto; padding: 5px; border-radius: 4px">You do not have permission to access this feature. Please talk to your admin if you require access.</h3>
    </div>
    <compDeliveryModal v-if="completedDelivery" :form="form" :openInvoice="openInvoice" :payload2="payload2" :endpoint="getEndpoint()" :isStatus="isStatus" @close="close" :noteName="noteName" notePlaceholder="Enter notes here..."  :successName="successName" :isEdit="isEdit"/>

    <SuccessModal @close="close" v-if="displaySuccessModal" :message="$store.state.syncStatus?.invoices?.syncError" :first_spec_line="$store.state.syncStatus?.invoices?.syncError?.response?.data?.errors ? $store.state.syncStatus?.invoices?.syncError?.response?.data?.message + ' ' + Object.values($store.state.syncStatus?.invoices?.syncError?.response?.data?.errors).flat()[0] : $store.state.syncStatus?.invoices?.syncError" statusMessage="Sync Invoices" />
    <!-- <MoveToLocationModal v-if="moveToLocationModal" @close="close" :payload="payload" type="Invoices" /> -->
  </div>
</template>


<script>
// @ is an alias to /src
// import NewTransfer from '@/components/actions/NewTransfer.vue';
import SignatureModal from '@/components/modals/SignatureModal.vue';
import DeliveryModal from '@/components/modals/DeliveryModal.vue';
import compDeliveryModal from '@/components/modals/compDeliveryModal.vue';
import SuccessModal from '@/components/modals/SuccessModal.vue';
// import MoveToLocationModal from '@/components/modals/MoveToLocationModal.vue';
import axios from 'axios';
import debounce from "debounce"
import config  from "../../config.js"

export default {
  components: {
      SignatureModal,
      DeliveryModal,
      compDeliveryModal,
      SuccessModal
      // MoveToLocationModal
  },
  data(){
    return{
        dynamicName: config,
        checked:false,
        successName:'Delivery Received!',
        facility_id:null,
        openInvoice:null,
        isStatus:"",
        completedDelivery: false,
        requireNotes: false,
        endpoint: '',
        loadingError:'',
        displayNewTransferModal: false,
        displayActionModal: false,
        displayDeliveryModal: false,
        // toAction:{
        //     uuid:'',
        //     action: 'Approve',
        //     drugs:[]
        // },

        toAction:{
            uuid:'',
            action: ''
        },
      selectedStatuses: [
        { status: 'Pending' },
        { status: 'Partial' },
        { status: 'Completed' },
        { status: 'Reversed' }
      ],
      statusSelect: [
        { status: 'Pending' },
        { status: 'Partial' },
        { status: 'Completed' },
        { status: 'Reversed' }
      ],
        tags:{
            s8:false,
            s4: false,
            antimicrobial: false,
        },
      transferType:'',
      drugNames: [],
      registerData: [],
      filters:{
          processed: false,
          pending: false,
          all: true,
          in_progress: false,
          completed: false
      },
      types:{
          send: true,
          receive: true
      },
      toCache:[],
      date:[],
      clearForm:{
            sort_direction: 'desc',
            col: 'created_at',
            page:1,
            filters:{
                tags:[],
                status:[],
                party:'',
                drug:'',
                date:{
                  start:'',
                  end:''
                },
                show_active:true,
            },
            
        },
      form:{
            sort_direction: 'desc',
            col: 'created_at',
            facility_id: this.$store.state.currentLocation.uuid,
            page:1,
            filters:{
                // tags:[],
                status:[],
                party:'',
                drug:'',
                date:{
                  start:'',
                  end:''
                },
                invoice_number:'',
                show_active:true,
            },
            
        },
      testData:[
      ],
      payload:{},
      payload2:{},
      noteName:'',
      moveToLocationModal:false,
      displaySuccessModal: false,
      isEdit:false,
      syncFrom: null,
      syncButtonDisable: false
    }
  },
  methods:{
    toggleSelectAll(select){
      this.getDrugEntries.forEach(entry=>{
        if(entry.status == 'pending' || entry.status == 'partial'){
          if(select){
          entry.to_sign = true;
        } else{
          entry.to_sign = false;
        }
      }
      })
    },
    // moveToOtherLocation(){
    //   if (this.ordersToSign > 0) {
    //     this.payload = {
    //       invoice_id:[]
    //     }
    //     this.getDrugEntries.forEach(item=>{
    //       if(item.to_sign){
    //         this.payload.invoice_id.push(item.invoice_id);
    //       }
    //     })
    //     this.moveToLocationModal = true
    // }
    // },
    getEndpoint(){
      if (this.noteName === 'Reverse Invoice') {
        return "/warehouse/reverse-invoice-order"  
      }else{
        return "/warehouse/sign-off-invoices"
      }
      
    },
    cancelDelivery(item) {
        const delData = [{
					invoice_uuid:item?.invoice_id

      }]

      this.displayActionModal = true;
      this.endpoint = '/warehouse/cancel-invoice-order';
      this.payload=delData;
      console.log( this.payload,"delData");
    },
    getDynamicName(){
      const matchedItem = this.dynamicName.find(item => item.name === this.$store.state.user.integration_type)
      return matchedItem ? matchedItem.warehouse_invoices : "Warehouse Invoices"
    },

    // toggleSelectAll(select) {
    // const hasPending = this.getDrugEntries.some(entry => entry.status === 'pending');
    //    if (hasPending) {
    //     this.getDrugEntries.forEach(entry => {
    //         if (entry.status === 'pending') {
    //          if(select){
    //            entry.to_sign = true;
    //          } else{
    //            entry.to_sign = false;
    //          }
    //         }
    //          if (entry.status == 'partial' && entry.to_sign) {
    //          return  entry.to_sign = false;
    //          }
    //     });
    //     } else {
    //     this.getDrugEntries.forEach(entry => {
    //       if (entry.status === 'partial') {
    //          if(select){
    //            entry.to_sign = true;
    //          } else{
    //            entry.to_sign = false;
    //          }
    //         }
    //          if (entry.status == 'pending' && entry.to_sign) {
    //            entry.to_sign = false;
    //          }
    //     });
    // }
    // },

    async getretrieveInvoiceDetails(delivery){
      await axios.post('/warehouse/retrieve-invoice-details', {
         invoice_id: delivery.invoice_id
       }, this.$store.state.header).then(res => {
         let entries = res.data.data[0];
             delivery.note = entries?.note
             delivery.reference_number = entries?.reference_number
             delivery.medications = entries?.medications
       }).catch(err => {
         this.loadingError = err;
         console.log('this is the error, err');
       })
    },

    async signOrders() {
    if (this.ordersToSign > 0) {
      this.payload = {
        invoice_id:[]
      }
      this.endpoint = '/warehouse/sign-off-invoices'
      for (const order of this.getDrugEntries) {
        if(order.to_sign){
          this.isStatus = "bulk"
          this.payload.invoice_id.push(order.invoice_id);
          if (this.payload.invoice_id.length === 1) {
            if (!order.medications) {
              await this.getretrieveInvoiceDetails(order);
            }
            order.medications = order.medications.map(item => ({
              ...item,
              lost:false,
              destroy:false,
              qty: item.count
            }));
            this.payload2 = order;
          } else {
            this.payload2 = {};
          }
          
          if (order.has_scheduled_items) {
            this.payload.has_scheduled_items = true;
          }
        }
      }
      // this.displayActionModal = true;
      this.completedDelivery = true;
      this.openInvoice = this.payload;
      this.noteName = 'Complete Delivery';
    }
  },
    signSingleOrder(item){
      this.payload = {
        invoice_id:[item?.invoice_id],
        has_scheduled_items: item?.has_scheduled_items
      }
      this.endpoint = '/warehouse/sign-off-invoices'
      // this.displayActionModal = true;
      this.isStatus = item?.status
      this.completedDelivery = true
      this.openInvoice = this.payload
    },
    close(value){
        console.log('delivery modal should be closing here, outside if');
        if(value){
            console.log('delivery modal should be closing here');
            this.displayActionModal = false;
            this.displayDeliveryModal = false;
            this.moveToLocationModal = false;
            this.completedDelivery = false;
            this.payload = {}
            this.payload2 = {}
            this.isEdit = false
            this.displaySuccessModal = false
        }
    },
		closeModal(){
			this.displayActionModal=false;
		},
    // selectItem(item, index) {
    //   console.log('this the selected log', item, index)
    //   // let refid=`expand-${index}`;
    //   if (this.getDrugEntries[index].to_sign) {
    //     this.getDrugEntries[index].to_sign = false;
    //   } else {
    //     if (this.getDrugEntries[index].status === 'partial') {
    //       this.getDrugEntries.forEach((item)=>{
    //         if (item.status === 'pending') {
    //           item.to_sign = false
    //         }
    //       })
    //     }else if(this.getDrugEntries[index].status === 'pending'){
    //       this.getDrugEntries.forEach((item)=>{
    //         if (item.status === 'partial') {
    //           item.to_sign = false
    //         }
    //       })
    //     }
    //     this.getDrugEntries[index].to_sign = true;
    //   }
      
    // },
    selectItem(item, index) {
      console.log('this the selected log', item, index)
      // let refid=`expand-${index}`;
      if (this.getDrugEntries[index].to_sign) {
        this.getDrugEntries[index].to_sign = false;
      } else {
        this.getDrugEntries[index].to_sign = true;
      }

    },
    checkboxCondition(item) {
      if (item.status === 'pending' || item.status === 'partial') {
      if (this.$store.state.user.role.permissions.includes('signature_primary')) {
          return true
      } else {
          if(this.$store.state.user.role.permissions.includes('signature_witness')){
            if (item.status === 'pending'  || item.status === 'partial') {
              return true
            }else{
              return false
            }
          }else if(this.$store.state.user.role.permissions.includes('single_signature_primary')){
              return true
          }
      }
    }
    },
    toggleNewTransferModal(type){
        this.transferType = type;
        this.displayNewTransferModal = true;
    },
     transferAction(selectedAction, item){
        console.log('this is the transfer being actioned', item);
        this.toAction = {
            action: selectedAction,
            invoice_id: item.invoice_id,
            notes:item.note
        }
        // this.displayActionModal = true;
        // console.log('this is the action id', this.toAction);
        if(this.toAction.action === 'Reverse'){
          const allTags = []
          item.medications.forEach(item => {
            if (item.tags) {
              allTags.push(...item.tags.flat(1))
            }
          })
        this.completedDelivery = true
        this.isStatus = 'Reverse Invoice';
        this.noteName = 'Reverse Invoice',
        this.openInvoice = {
          invoice_id: item.invoice_id,
          tags: allTags
        }
      }
    },
    isSuccessful(value){
      console.log('this has been', value);
      if (value) { 
        this.displayActionModal = false;
        
      }
      this.$store.dispatch('getPurchaseInvoices',this.form)
    },
    // SORT DATA BY COLUMN AND ORDER
    sortBy(col, dir){
      this.form.col = col;
      this.form.sort_direction = dir;
      this.loadEntries();
    },
    showContent(delivery, index){
        console.log('this the selected log', delivery)
        // let refid=`expand-${index}`;

        if(delivery.display && (delivery.display === true)){
            document.getElementById(`expand-${index}`).style.display = 'none';
            delivery.display = !delivery.display;
        } else{
            delivery.display = true;
            console.log('expanding range for this object');
            document.getElementById(`expand-${index}`).style.display = 'table-row';
            // axios.post('/single-delivery', {uuid: delivery.uuid}, this.$store.state.header).then(res=>{
            //     console.log('this is the state function to get a single delivery', res.data);
            //     delivery.expandedInfo = {
            //       notes:res.data.note,
            //       drugs:res.data.units || ""
            //     }
            // }).catch(err=>{
            //   this.loadingError = err;
            //   console.log('this is the error, err');
            // })
          axios.post('/warehouse/retrieve-invoice-details', {
            invoice_id: delivery.invoice_id
            // facility_id: JSON.parse(localStorage.getItem('currentLocation')).uuid,
            // integration_id: "2",
            // invoice_number: delivery.invoice_number
          }, this.$store.state.header).then(res => {
            let entries = res.data.data[0];
                delivery.note = entries?.note
                delivery.reference_number = entries?.reference_number
                delivery.medications = entries?.medications
          }).catch(err => {
            this.loadingError = err;
            console.log('this is the error, err');
          })
        }
    },
    selectDrugName(name){
      this.form.filters.drug = name;
      this.drugNames = [];
    },
    loadEntries(){
      console.log('this is the date', this.date);
      if(this.date && this.date.length > 0){
        if(typeof this.date == "string"){
          this.form.filters.date.start = this.date.split("-")[0]
          this.form.filters.date.end = this.date.split("-")[1]
        } else{
          this.form.filters.date.start = new Date(this.date[0]).toLocaleDateString("en-AU");
          this.form.filters.date.end = this.date[1] ? new Date(this.date[1]).toLocaleDateString("en-AU") : new Date(this.date[0]).toLocaleDateString("en-AU");
        }
        this.$store.dispatch('getPurchaseInvoices',this.form)
      } else{
        this.form.filters.date.start ='';
        this.form.filters.date.end = '';
        this.$store.dispatch('getPurchaseInvoices',this.form)
      }
      console.log('request: ', this.$store.state.latestDrugEntriesMutationRequest);
    },
    refreshList(){
     this.$store.dispatch('refreshSyncList', 'invoices')
     this.loadEntries()
     clearInterval(this.$store.state.syncinvoices)
     this.syncFrom = null
    },
    debouncedLoadEntries: debounce(function() {
      this.loadEntries();
    }, 1000),

    displayPage(page){
      this.form.page = page;
      this.$store.dispatch('getPurchaseInvoices',this.form)
    },
    updateStatusList(){
        this.checked = false
        this.form.filters.status = [];
        console.log('updating status list')
        this.selectedStatuses.forEach(statusObject=>{
            for (const [key, value] of Object.entries(statusObject)) {
                console.log('this is the key', key);
                this.form.filters.status.push(value.toLowerCase());
             }
        });
        if (!this.form.filters.show_active) {
          this.form.filters.show_active = true;
        }
        this.debouncedLoadEntries();
    },
    updateListing(){
      this.form.filters.status = [];
      if (this.checked) {
        this.selectedStatuses = [
            new Proxy({ status: 'Pending' }, {}),
            new Proxy({ status: 'Partial' }, {})
        ];
        this.selectedStatuses.forEach(statusObject=>{
            for (const [key, value] of Object.entries(statusObject)) {
                this.form.filters.status.push(value.toLowerCase());
             }
        });
        if (!this.form.filters.show_active) {
          this.form.filters.show_active = true;
        }
      }else {
        this.selectedStatuses =[
        {status:'Pending'},
            {status:'Partial'},
            {status:'Completed'}
        ]
      }
      this.loadEntries();
    },
    updateType(){
        this.form.filters.type = [];
        for (const [key, value] of Object.entries(this.types)) {
            if(value == true){
                this.form.filters.type.push(key)
            }
        }
    },
    clearEntriesFilters(){
      this.form = {
            sort_direction: 'desc',
            col: 'created_at',
            facility_id: this.$store.state.currentLocation.uuid,
            page:1,
            filters:{
                // tags:[],
                status:[],
                party:'',
                drug:'',
                date:{
                  start:'',
                  end:''
                },
                invoice_number: '',
                show_active:true,
            },
            
        },
      this.selectedStatuses = [
            {status:'Pending'},
            {status:'Partial'},
            {status:'Completed'},
            {status:'Reversed'}
          ]
      this.date = [];
      this.checked = false
      this.syncFrom = null
      this.$store.dispatch('getPurchaseInvoices',this.form)
    },

     editDelivery(item){
      this.completedDelivery = true
      this.openInvoice = {
         invoice_id: [ item?.invoice_id ],
         has_scheduled_items: item?.has_scheduled_items
      }
      item.medications = item.medications.map(item => ({
              ...item,
              qty: item.count
            }));
      this.payload2 = item
      this.isEdit = true;
      this.noteName = 'Edit Delivery';
    },

    async completedDeliveryDilog(item){
        this.completedDelivery = true
      this.openInvoice = {
         invoice_id: [ item?.invoice_id ],
         has_scheduled_items: item?.has_scheduled_items
      }
      item.medications = await item.medications.map(item => ({
              ...item,
              lost:false,
              destroy:false,
              qty: item.count
            }));
      this.payload2 = item
      this.isStatus = item?.status;
      this.noteName='Complete Delivery';
    }
  },
   mounted(){
     // Code for autosyncing through pulling (loadEntries() function call should be commented)
    this.loadEntries();

    this.toCache = [];
    // Code for autosyncing through pulling
    // Call the sync function if the user stays on the page for 2 seconds after the page is rendered
    if (this.$store.state.user.integration_type === 'carton-cloud' || this.$store.state.user.integration_type === 'microsoft') {
      this.syncButtonDisable = true
      this.syncTimeoutId = setTimeout(async () => {
        this.$store.dispatch('syncEntries', {endpoint: '/integration/sync-invoices',type: 'import_invoices',value: false, sync: 'invoices', syncFrom: this.syncFrom})
        this.syncButtonDisable = false
      }, 2000)
    }
  },
  beforeUnmount(){
      // Clear the polling interval and timeout when navigating to another page
      clearInterval(this.$store.state.syncinvoices)
      this.$store.commit('setSyncStatus', {key:'invoices', syncError: '', syncUUIDSucces: false, syncLoader:false });
      if (this.syncTimeoutId) {
        clearTimeout(this.syncTimeoutId);
      }
  },
  computed:{
    cartonCloudRestriction() {
      // this is a special carton cloud integration restriction for warehouse
      // that restricts manual new purchase or sales
      // orders creation as per DNH requirements
      // to limit creation of extraneous invoices
      if (this.$store.state.user.integration_type === 'carton-cloud') {
        return (this.$store.state.user.integration_type === 'carton-cloud' && !this.$store.state.user.role.permissions.includes('can_do_admin_roles'));
      } else return false;
    },
    getDrugEntries (){
      return this.$store.getters.getDrugEntries
    },
    getCount(){
      return (this.$store.state.LastSync?.invoicesLastSynced?.count >= 12 || this.$store.state.syncStatus?.invoices?.syncError) ? true : false
    },
    ordersToSign(){
      let num = 0;
      if (this.getDrugEntries) {
        this.getDrugEntries.forEach((item) => {
          if (item.to_sign) {
            num += 1;
          }
        })

      }
      return num;
    },
  },
  watch:{
    date:{
     handler(newValue){
      if (newValue?.length > 0) {
        this.debouncedLoadEntries()
      }
     },
     deep: false
    }
  }
}
</script>
<style lang="scss" scoped>
.p-checkbox-icon{
  line-height: normal !important;
}
input.p-inputtext::placeholder {
    color: #282828 !important;
    font-weight: normal !important;
    font-size: 15px !important;
  }
.filters {
  div {
    margin-right: 0px !important;
  }
}
.submit-button {
    margin-right: 0px !important;
}
.clear-button {
    margin-right: 0px !important;
}
input {
    max-height: 100% 
  }
.disabledButton{
  background-color: #b8b8b8 !important;
  cursor: auto ;
}  
.statuswidth{
  text-align: center;
  padding: 2px 8px;
  min-width: max-content;
}
.complete-button{
  font-weight: 600;
}
</style>