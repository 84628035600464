<template>
  <div class="modal-mask" v-if="openFirstModal" >
    <div class="modal-wrapper">
      <div class="modal-container"
        style="min-width: 43.25rem;width: fit-content; max-width: 63rem;border-radius: 12px;">
        <div class="modal-header" >
          <slot name="header">
            <div>
              <h3 class="form-header" style="color: #282828;">{{ noteName }}</h3>
            </div>
            <button @click="closeModal(0)" class="red-close-button" style="width: 30px !important">
              <i class="pi pi-times" style="padding: 0"></i>
            </button>
          </slot>
        </div>
        <div class="modal-body" style="padding: 20px 20px 0px 20px; text-align: left">
          <slot name="body">
           <div class="manageFormSectionPanel">
            <div class="left-form-section-panel" style="flex: auto;">

                  <div class="dropdownClass" v-if="$store.state.company.type == 'warehouse' && noteName !== 'Reverse Invoice' && noteName !== 'Reverse Order' && isEdit">
                    <Dropdown :disabled="isEdit" :placeholder="noteName == 'Edit Order' ? 'Search Client...': 'Supplier name...'" v-model="supplierName" :editable="true" style="width: 20em; display: flex" />
                  </div>

                  <div class="dropdownClass" v-if="$store.state.company.type == 'warehouse' && noteName !== 'Reverse Invoice' && noteName !== 'Reverse Order'">
                    <Dropdown :disabled="isEdit" v-model="searchedLocation" placeholder="Location (defaults to current location)" ref="location" @change="selectGetLocation()" @keyup="searchLocation()" :loading="loadingLocation" :options="refinedGetLocations" optionLabel="name" :editable="true" style="width: 23em; max-width: 23rem; display: flex" />

                    <div class="calenderdatelabel" v-if="noteName == 'Send Order'">
                     <label for="destructLabel">Move to Destruction</label>
                     <InputSwitch id="destructLabel" v-model="checkedDestruct"/>
                    </div>
                  </div>

                  <Calendar readonly v-if="isEdit" v-model="Delivery_date" placeholder="Date..." dateFormat="dd/mm/yy" class="text-search-container search-container" style="display: flex; margin-bottom: 5px;max-width: 23rem;" />
                  <input v-if="isEdit" v-model="Reference_number" placeholder="Reference Number" style="position: relative; width: 100%; margin-bottom: 5px; background-color: #e5f1fe !important;max-width: 23rem;" />

              <p class="note-header">Note</p>
              <textarea v-model="note" name="note" id="note-modal" rows="12"
                :placeholder="notePlaceholder" style="
                  width: 100%;
                  border-top-right-radius: 0px;
                  border-top-left-radius: 0px;
                "></textarea>
            </div>
              <div class="right-form-section-panel rightsideSection" v-if="payload2 && payload2.medications && payload2.medications.length">
                    <table class="data-table" cellspacing="0" style="overflow: hidden;">
                      <tr class="table-headers" >
                        <th>Drug Name</th>
                        <th v-if="isValidStatus(payload2)" style="width: 130px;">Status</th>
                        <th style="width: 125px;">Quantity</th>
                        <th style="width: 90px;padding: 0 10px;" v-if="!isEdit">Lost</th>
                        <th style="width: 90px;" v-if="!isEdit">Destroy</th>
                      </tr>
                      <tbody class="table-data tableData" v-for="(item, index) in payload2?.medications" :key="index">
                        <tr>
                          <td>{{item.medication}}</td>
                          <td v-if="isValidStatus(payload2)" style="width: 130px;">
                             <span v-if="item?.deprecated" class="s8-tag">Deprecated</span>
                             <span v-else>
                               --
                             </span>
                           </td>
                          <td style="padding-right: 10px;">
                            <input :disabled="!isEdit ? !item.lost && !item.destroy : false" v-model="item.qty" min="0" :max="!isEdit && item.count" type="number" placeholder="Enter qty" style="width: 100%" @change="updateQty(item)" />
                          </td>
                          <td v-if="!isEdit">
                            <Checkbox :binary="true" :disabled="cartonCloudRestriction || (item.count == 0)" v-model="item.lost" @change="handleSelectType(item, 'lost')" class="Checkbox" />
                          </td>
                          <td v-if="!isEdit">
                            <Checkbox :binary="true" :disabled="cartonCloudRestriction || (item.count == 0)" v-model="item.destroy" @change="handleSelectType(item, 'destroy')" class="radioButton" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
          </slot>
        </div>
        <p style="color: red" v-if="errorMessage">{{ errorMessage }}</p>
        <div class="modal-footer" style="display: flex; justify-content: flex-end; padding: 10px 20px;margin: 5px 0px;">
          <div style="display: flex; gap: 10px">
            <button class="button pending-button" @click="closeModal(0)">Cancel</button>
            <button v-if="!isEdit" :class="buttonchangeUi() ? 'button completed-button' : 'button grey-button'" style="min-width: max-content;padding: 0px 55px;height: 35px;margin: 0px;"  @click="gotosign()">{{ noteName }}</button>

            <button v-if="isEdit" @click="editInvoicewithSign()" :class="buttonchangeUi()  && note && !payload2?.deprecated ? 'button completed-button' : 'button grey-button'" style="min-width: max-content;padding: 0px 28px;height: 35px;margin: 0px;">{{noteName == 'Edit Order' ?  'Confirm Order' : 'Receive Delivery'}}</button>
            <button v-if="isEdit" @click="editInvoice()" :class="buttonchangeUi() && note ? 'button completed-button' : 'button grey-button'" style="display: flex;align-items: center;min-width: max-content;padding: 0px 28px;height: 35px;margin: 0px;"><i class="pi pi-spin pi-spinner" v-if="loadingSubmit"></i>Save as Pending {{noteName == 'Edit Order' ?  'Order' : 'Delivery'}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <SignatureModal v-if="displayActionModal" :payload="checkedDestruct ? payload2?.orders : isEdit ? payloads2 : getPayload()" @close="closeSignatureModal()" :endpoint="checkedDestruct ? '/warehouse/destroy-orders' : isEdit ? '/warehouse/update-single-invoice-order' : endpoint" @isSuccessful="isSuccessful" :isStatus="isStatus" :endpoint2="checkedDestruct ? null : isEdit ? endpoint : null"  :payload2="checkedDestruct ? null : isEdit ? payload : null" />

  <!-- Delivery Recieved -->
  <!-- <div class="modal-mask" v-if="openstatus === 3">
    <div class="modal-wrapper">
      <div class="modal-container"
        style="min-width: 62rem; width: 62rem;border-radius: 12px;border: 4px solid #00AD50;max-height: 86vh;">
        <div class="modal-header">
          <slot name="header">
            <div>
            </div>
            <button @click="closeModal(0)" class="red-close-button" style="width: 30px !important">
              <i class="pi pi-times" style="padding: 0"></i>
            </button>
          </slot>
        </div>
        <div class="modal-body" style="padding: 20px 20px 0px 20px; text-align: left">
          <slot name="body">
            <div class="deliverysuccess">
              <h1 style="width: 40%;">{{ successName }}</h1>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div> -->

  <MoveSuccessModal v-if="moveToSuccessModal" @close="close(true)" :reminders="reminders" :type="checkedDestruct ? 'Order' : noteName == 'Send Order' ? 'Order' : 'Invoice'" :action="checkedDestruct ? 'Destruct' : 'Signoff'" :tableHead="checkedDestruct ? [ 'Order Number', 'Status'] : ['Order Number', 'Items' , 'Requested' , 'In Stock']" :tableCell="checkedDestruct ? ['order_number', 'status'] : ['order', 'item_name', 'requested_qty', 'in_stock']"/>

</template>

<script>
import SignatureModal from './SignatureModal.vue';
import MoveSuccessModal from './MoveSuccessModal.vue';
import axios from 'axios';
import { debounce } from 'debounce';

export default {
  props: ['openInvoice','endpoint','isStatus','noteName','notePlaceholder','successName','form', 'payload2',  'isEdit', 'istrigger'],
  components: {
    SignatureModal,
    MoveSuccessModal
  },
  data() {
    return {
      data: null,
      selectedRadio:true,
      signsuccess1: false,
      signsuccess2: false,
      note: "",
      signature: {
        firstuser: {
          userName: "",
          password: ""
        },
        seconduser: {
          userName: "",
          password: ""
        }
      },
      payload: [],
      payloads2:[],
      errorMessage: "",
      // searchedLocation: {name: this.$store.state.currentLocation?.name, uuid : this.$store.state.currentLocation?.uuid},
      // refinedGetLocations:[{name: this.$store.state.currentLocation?.name, uuid : this.$store.state.currentLocation?.uuid}],
      searchedLocation: this.$store.state.currentLocation?.name,
      refinedGetLocations:[],
      loadingLocation: false,
      location_uuid: this.$store.state.currentLocation?.uuid,
      submitLoading: false,
      moveToSuccessModal: false,
      reminders:[],
      displayActionModal: false,
      openFirstModal: true,
      supplierName: "",
      Delivery_date: "",
      Reference_number: "",
      loadingSubmit: false,
      checkedDestruct: false
    };
  },

  methods: {
    closeModal() {
      this.$emit("close", true);
      this.note = ''
    },
    close(){
      this.closeModal()
    },
    changestatus(value) {
      this.$emit("changestatus", value);
    },
    gotosign() {
      if (this.buttonchangeUi()) {
        this.errorMessage = ''
        this.displayActionModal = true
          this.payload = {
           notes: this.note,
           invoice_id: this.openInvoice.invoice_id,
            ...(this.openInvoice?.tags && this.openInvoice?.tags.length > 0 ? { drugs: [{ tags: [...this.openInvoice.tags] }] } : {}),
            has_scheduled_items: this.openInvoice?.has_scheduled_items,
            ...(this.noteName !== 'Reverse Invoice' ? { location_id: this.location_uuid } : {})
         }
      }else{
        this.errorMessage = "Location is Required"
      }
    },
    async editInvoicewithSign() {
      if (this.buttonchangeUi() && this.note && !this.payload2?.deprecated) {
      this.payload = {
        notes: this.note,
        invoice_id: this.openInvoice.invoice_id,
        ...(this.openInvoice?.tags && this.openInvoice?.tags.length > 0 ? { drugs: [{ tags: [...this.openInvoice.tags] }] } : {}),
        has_scheduled_items: this.openInvoice?.has_scheduled_items,
        location_id: this.location_uuid
      }
        if (this.payload2?.medications.length > 0) {
          const findLocation = this.$store.state.availableLocations.find((item) => item?.name == this.payload2.location_name)
          const checkData = this.payload2?.medications.map(item => {
            return axios.post('/get-stock',
              { alias_id: item.alias_id, location_id: findLocation?.uuid },
              this.$store.state.header
            ).then(res => {
              return { data: res.data };
            }).catch(error => {
              return { data: { alias: { alias_id: item.alias_id }, qty_left: 0 } };
            });
          });

          const results = await Promise.allSettled(checkData);

          results.forEach(result => {
            if (result.status === 'fulfilled') {
              const drug = this.payload2?.medications.find(d => d.alias_id === result.value.data.alias.alias_id);
              if (drug) {
                drug.drug = result.value.data.uuid
                drug.qty_left = result.value.data.qty_left;
                drug.form = result.value.data.alias.form
              }
            } else {
              console.error(`Error fetching stock for drug with UUID`, result.reason);
            }
          });
        }
        const drugslist = await this.payload2?.medications.map((item) => ({
          tags: item.tags,
          stock: item.medication,
          form: item.form,
          alias_id: item?.alias_id,
          drug: item.drug,
          qty_left: item.qty_left,
          qty: item?.qty
        }))
          const editpayload = {
          type: this.noteName == 'Edit Delivery' ? 'invoice' : 'order',
          invoice_id: this.payload2?.invoice_id,
          reference_number: this.Reference_number,
          notes: this.note,
          drugs: drugslist,
        }
        this.payloads2 = editpayload
        this.displayActionModal = true
        this.errorMessage = ''
      } else {
        this.errorMessage = this.payload2?.deprecated ? "Cannot sign with a deprecated drug." : "Location and Notes are Required"
      }
    },
    async editInvoice() {
      if (this.buttonchangeUi() && this.note) {
      this.loadingSubmit = true
      this.errorMessage = ''
        if (this.payload2?.medications.length > 0) {
          const findLocation = this.$store.state.availableLocations.find((item) => item?.name == this.payload2.location_name)
          const checkData = this.payload2?.medications.map(item => {
            return axios.post('/get-stock',
              { alias_id: item.alias_id, location_id: findLocation?.uuid },
              this.$store.state.header
            ).then(res => {
              return { data: res.data };
            }).catch(error => {
              return { data: { alias: { alias_id: item.alias_id }, qty_left: 0 } };
            });
          });

          const results = await Promise.allSettled(checkData);

          results.forEach(result => {
            if (result.status === 'fulfilled') {
              const drug = this.payload2?.medications.find(d => d.alias_id === result.value.data.alias.alias_id);
              if (drug) {
                drug.drug = result.value.data.uuid
                drug.qty_left = result.value.data.qty_left;
                drug.form = result.value.data.alias.form
              }
            } else {
              console.error(`Error fetching stock for drug with UUID`, result.reason);
            }
          });
        }
        const drugslist = await this.payload2?.medications.map((item) => ({
          tags: item.tags,
          stock: item.medication,
          form: item.form,
          alias_id: item?.alias_id,
          drug: item.drug,
          qty_left: item.qty_left,
          qty: item?.qty
        }))
        const editpayload = {
          type: this.noteName == 'Edit Delivery' ? 'invoice' : 'order',
          invoice_id: this.payload2?.invoice_id,
          reference_number: this.Reference_number,
          notes: this.note,
          drugs: drugslist,
        }
        console.log("editpayload>>", editpayload);
        axios.post('/warehouse/update-single-invoice-order', editpayload, this.$store.state.header)
          .then(async response => {
            if (response?.status === 200) {
              this.errorMessage = ''
              this.loadingSubmit = false
              this.closeModal()
              if (this.noteName == 'Edit Delivery') {
                this.$store.dispatch('getPurchaseInvoices', this.form)
              } else {
                this.$store.dispatch('getOrders', this.form)
              }
            }
          }).catch(error => {
            this.loadingSubmit = false
            if (error.response && error.response.data && error.response.data.message) {
              this.errorMessage = error.response.data.errors ? error.response.data.message + ' ' + Object.values(error.response.data.errors).flat()[0] : error.response.data.message;
            } else {
              this.errorMessage = error.response.data;
            }

          })
      } else {
        this.errorMessage = "Location and Notes are Required"
      }
    },
    getPayload() {
      if (this.payload.length > 0 || this.payload) {
        return this.payload
      } 
    },
    closeSignatureModal(){
      this.displayActionModal = false
      this.payloads2 = []
      this.payload = []
    },
    buttonchangeUi() {
      return this.location_uuid && this.searchedLocation
    },
    async getretrieveInvoiceDetails(delivery) {
      try {
        const res = await axios.post('/warehouse/retrieve-invoice-details', {
          invoice_id: delivery.invoice_id
        }, this.$store.state.header);

        let entries = res.data.data[0]?.medications || [];
        delivery.medications = delivery.medications.map(item => {
          let entry = entries.find(e => e.invoice_item_id === item.invoice_item_id);
          return {
            ...item,
            stock_item_uuid: entry ? entry.stock_item_uuid : item.stock_item_uuid
          };
        });
      } catch (err) {
        this.errorMessage = err;
        console.log('this is the error:', err);
      }
    },

    async destroyPending(payload){
      await axios.post(`/location/${this.$store.state.currentLocation.uuid}/destroy`, payload , this.$store.state.header).then(res => {
         console.log("destroyPending>>", res)
       }).catch(err => {
         this.errorMessage = err;
         console.log('this is the error, err');
       })
    },

    async lostOutgoing(payload){
      await axios.post(`/location/${this.$store.state.currentLocation.uuid}/outgoing`, payload , this.$store.state.header).then(res => {
         console.log("lostOutgoing>>", res)
       }).catch(err => {
         this.errorMessage = err;
         console.log('this is the error, err');
       })
    },
    
    async isSuccessful(value, value2){
      console.log("value>>", value, value2);
      if (this.form) {
          if ((this.endpoint === "/warehouse/sign-off-invoices" || this.isStatus === 'Reverse Invoice') && this.istrigger !== false) {
            this.$store.dispatch('getPurchaseInvoices',this.form)
          }else if((this.endpoint === "/warehouse/sign-off-orders" || this.isStatus === 'Reverse Order') && this.istrigger !== false){
            this.$store.dispatch('getOrders',this.form)
          }
      }
      if (value) {
        this.payload = [];
        if (this.noteName == 'Reverse Invoice' || this.noteName == 'Reverse Order') {
          this.closeModal()
        }
        if (this.noteName !== 'Reverse Invoice' || this.noteName !== 'Reverse Order') {
          if (value?.reminders) {
            const remindersdata = {completed:value.completed,total:value.total,reminders:[]};
            value?.reminders?.forEach(item => {
              item.items.forEach(element => {
                element.order = item.order;
                remindersdata.reminders.push(element);
              });
            });
            this.reminders = remindersdata;
            this.moveToSuccessModal = true;
            this.openFirstModal = false;
            this.displayActionModal = false
          }
          this.$emit("success");
          if (this.checkedDestruct && value2) {
            const remindersdata = {completed:value.status == 'completed' ? value2?.orders.length : (value2?.orders.length - value.message?.length) ,total:value2?.orders.length,reminders:[]};
            this.payload2.OrderDetails?.forEach((item, index) => {
              const findfaileddrug = value.message?.find(response => response == item.uuid)
              remindersdata.reminders.push({ order_number : item.ordernumber, status: findfaileddrug ? `${value.status}` : 'completed'})
            })
            this.reminders = remindersdata;
            this.moveToSuccessModal = true;
            this.openFirstModal = false;
            this.displayActionModal = false
          }
        }
        if (this.payload2 && this.payload2.medications && this.payload2.medications.length) {
          const lostData = this.payload2.medications.filter(item=> item.lost)
          const DestroyData = this.payload2.medications.filter(item=> item.destroy)
          if (value2) {
            if (lostData.length > 0 || DestroyData.length > 0) {
              await this.getretrieveInvoiceDetails(this.payload2)
            }
            const finallostData = this.payload2.medications.filter(item=> item.lost)
            const finalDestroyData = this.payload2.medications.filter(item=> item.destroy)
            if (finallostData.length > 0) {
              const finallostDrugs = finallostData.map((item) => ({
                drug: item.stock_item_uuid,
                qty: item.qty,
                alias: item.medication,
                tags: item.tags
              }))
              const lostPayload = {
                notes: value2?.notes,
                lost_damaged: "loss",
                drugs: finallostDrugs,
                patient_uuid: "",
                verification: value2?.verification
              }
              this.lostOutgoing(lostPayload)
            }
            if (finalDestroyData.length > 0) {
              const finaldestroyDrugs = finalDestroyData.map((item) => ({
                drug: item.stock_item_uuid,
                qty: item.qty,
                alias: item.medication,
                tags: item.tags
              }))
              const destroyPayload = {
                drugs: finaldestroyDrugs,
                notes: ` ${value2?.notes}. Stock quarantined in safe awaiting Destruction`,
                method: "",
                pending: true,
                prescriber_uuid: "",
                patient_uuid: "",
                courier_name: "",
                courier_notes: "",
                verification: value2?.verification
              }
              this.destroyPending(destroyPayload)
            }
            this.$emit("success");
          }
        }
      }
    },
    selectGetLocation() {
      this.location_uuid = this.searchedLocation.uuid;
    },
    searchLocation: debounce(function () {
      this.loadingLocation = true;
        axios.post('/get-locations', { drug_register: true }, this.$store.state.header).then(res => {
          console.log('these are the returned locations', res.data);
          this.$store.dispatch('getRefreshToken');
          let refined = [];
          res.data.locations.forEach(location => {
              refined.push(location);
          })
          this.refinedGetLocations = refined;
          // if (this.refinedGetLocations.length) {
          //   this.$refs.location.show();
          // }
          if (this.refinedGetLocations.length == 1) {
            this.location_uuid = this.refinedGetLocations[0].uuid;
          }
          this.searchedLocation = this.$store.state.currentLocation?.name
          this.loadingLocation = false;
        }).catch(err => {
          if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
            console.log('yes, it does include expired')
            this.$router.push('/login');
          }
        });
    }, [500]),

    handleSelectType(item, type) {
      if (type === "lost") {
        if (item.lost) {
          item.destroy = false;
        }
      } else if (type === "destroy") {
        if (item.destroy) {
          item.lost = false;
        }
      }
      if (!item.lost && !item.destroy) {
        item.qty = item.count
      }
    },
    
    updateQty(item) {
      if (!this.isEdit) {
        if (item.qty > item.count) {
          item.qty = item.count;
        }
      }
    },
    isValidStatus(item){
      return item?.medications?.some(item => item?.deprecated)
    }
  },
  computed: {
    cartonCloudRestriction() {
      // this is a special carton cloud integration restriction for warehouse
      // that restricts manual new purchase or sales
      // orders creation as per DNH requirements
      // to limit creation of extraneous invoices
      if (this.$store.state.user.integration_type === 'carton-cloud') {
        return (this.$store.state.user.integration_type === 'carton-cloud' && !this.$store.state.user.role.permissions.includes('can_do_admin_roles'));
      } else return false; // return false for a plain warehouse, warehouse minus carton cloud integration and aged care
    },
  },
  mounted() {
    console.log("payload2>>", this.payload2);
    this.searchLocation()
    if (this.payload2) {
      this.supplierName = this.noteName == 'Edit Order' ? this.payload2?.ship_to_name : this.payload2?.supplier_name
      this.Delivery_date = this.payload2?.delivery_date,
      this.Reference_number = this.payload2?.reference_number
      this.note = this.payload2?.note
      this.searchedLocation = this.payload2?.location_name
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

#Sign {
  margin-top: 8px !important;
}

.calendar-input-container {
  background: none !important;
}

.calendar-input-container>input {
  background: none !important;
}

.signinputSuccessfully {
  border: 1px solid #00AD50 !important;
}

.signbtnSuccessfully {
  background-color: #00AD50 !important;
  color: white !important;
}

.deliverysuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 380px;
  width: 100%;
  text-align: center;
  color: #00AD50;

  h1 {
    margin: 0px;
    margin-top: -66px;
  }
}

.errorabs {
  color: red;
  position: absolute;
  top: 102px;
  margin: 0px;
}
.manageFormSectionPanel{
  display: flex;
  flex-direction: row;
  gap: 20px
  // display: grid;
  // grid-template-columns: 2.5fr 6fr;
  // grid-gap: 5px;
  // padding-left: 20px;
}
.dropdownClass{
  padding: 0px;
  justify-content: flex-start;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightsideSection{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 64%;
  max-height: 350px !important;
  padding: 0px;
}
.data-table{
  padding: 0px !important
}
.tableData{
  max-height: 350px !important;
  overflow: auto !important;
}
.radioButton {
  &.p-radiobutton-checked {
    .p-radiobutton-box {
      .p-radiobutton-icon {
        visibility: hidden !important;
      }
    }
  }
}
.table-headers{
    background-color: #f3f2f2;
}

.table-data:nth-child(odd){
    tr{
        background-color: #f3f2f2;
    }
    td{
        input{
            background-color: white;
        }
    }
    
}

.table-data:nth-child(even){
    tr{
        background-color: transparent;
    }
    td{
        input{
            background-color: transparent;
        }
    }
    
}

.data-table th, .data-table td{
  padding: 0px 10px !important
}
input{
  background-color: #d9d9d940 !important;
}
.calenderdatelabel{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  label{
    margin: 0px
  }
  
}
</style>
